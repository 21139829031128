<div>
  <main>
    <content-with-tabs-v2
      [tabLinks]="tabLinks"
      [tabsDescription]="'Settings.Menu.description' | translate"
      [ngClass]="{
        'bottom-bar-visible': bottomBarVisible && formDirty,
        'full-width': actionTitleVisible
      }"
    >
      <ng-container *ngIf="actionTitleVisible; else default_header">
        <app-team-search-header></app-team-search-header>
      </ng-container>

      <ng-template #default_header>
        <title-header-v2 [title]="'Header.settings' | translate"></title-header-v2>
      </ng-template>
    </content-with-tabs-v2>
  </main>
  <!-- REDESIGN: IS-516 -->
  <!-- <main>
    <content-with-tabs
      [tabLinks]="tabLinks"
      [tabsDescription]="'Settings.Menu.description' | translate"
      [tabsTemplate]="settingsTopMenu"
    ></content-with-tabs>
  </main>
  <ng-template #settingsTopMenu>
    <settings-top-menu [showProgressBar]="showProgressBar"></settings-top-menu>
  </ng-template> -->

  <!-- #region Settings Buttons -->
  <div *ngIf="bottomBarVisible && formDirty" class="container sticky-footer">
    <div class="col-12">
      <div class="row justify-content-between">
        <app-button
          class="mr-2"
          [disabled]="loading"
          (onClick)="discardSaving()"
          [config]="{
            type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
            text: 'Projects.discard' | translate,
            size: ButtonSize.LARGE
          }"
        ></app-button>
        <app-button
          (onClick)="startSaving()"
          [loading]="loading"
          [disabled]="loading"
          [config]="{
            type: ButtonTypes.NEW_PRIMARY_BLUE,
            text: 'Settings.User.saveUserData' | translate,
            size: ButtonSize.LARGE,
            prependSrc: '/assets/img/ic-check.svg'
          }"
        ></app-button>
      </div>
    </div>
  </div>
</div>
