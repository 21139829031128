import { Router } from '@angular/router';
import { debounce } from '@shared/decorators/debounce.decorator';
import { WindowHelper } from '@shared/helpers/window.helper';
import {
  Component,
  OnInit,
  TemplateRef,
  Input,
  OnDestroy,
  HostListener,
  ChangeDetectorRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { TabLink } from '@shared/interfaces/menu/tab-link.interface';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-content-tabs-v2',
  templateUrl: './content-tabs-v2.component.html',
  styles: [],
})
export class ContentTabsV2Component implements OnInit, AfterViewInit, OnDestroy {
  @Input() tabsTemplate: TemplateRef<any>;
  @Input() tabsDescriptionTemplate: TemplateRef<any>;
  @Input() tabsDescription: string;
  @Input() tabLinks: TabLink[];
  @Input() hideTopTabIconOnMobile: boolean = false;

  @Input() selectTabsOnMobile: boolean = false;

  @ViewChild('selectTab') selectTab: NgSelectComponent;

  @HostListener('window:resize')
  @debounce(30)
  resize() {
    this.changes?.detectChanges();
  }

  get menuTabsClass() {
    return {
      'mt-0': !this.tabsTemplate || this.hideTopTabIconOnMobile,
      'mt-md-3': this.hideTopTabIconOnMobile,
    };
  }

  get isMobile() {
    return WindowHelper.isMoreLessMD;
  }

  constructor(private changes: ChangeDetectorRef, private router: Router) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.setActiveTab();
  }

  setActiveTab() {
    this.selectTab
      ? this.tabLinks.map((tab) => {
          if (window.location.href.indexOf(tab.link) !== -1) {
            this.selectTab.writeValue(tab.link);
          }
        })
      : '';
  }

  onTabClick(clickedTab) {
    this.selectTab.writeValue(clickedTab.link);
    this.router.navigate([clickedTab.link]);
  }

  scrollToContent(link) {
    if (!WindowHelper.isMobileWidth) return;

    this.router.navigate([link]).then(() =>
      setTimeout(() => {
        document.querySelector('.base-panel ').scroll(0, 800);
      }),
    );
  }

  ngOnDestroy() {}
}
