import { FormGroup, FormBuilder } from '@angular/forms';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

declare var Datepair: any;
declare var $: any;

@Component({
  selector: 'pair-timepicker',
  templateUrl: './pair-timepicker.component.html',
  styleUrls: ['./pair-timepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PairTimepickerComponent implements OnInit, AfterViewInit {
  @ViewChild('pairTimePicker') pairTimePicker: ElementRef;
  timeContainer: HTMLElement;

  @Output() blurEvent: EventEmitter<string> = new EventEmitter();
  @Input() form: FormGroup;
  @Input() startControl: string = 'start';
  @Input() endControl: string = 'end';

  datePair: any;
  _config: any = this.defualtTimePickerConfig;

  @Input()
  set config(_config: any) {
    this._config = Object.assign(this.defualtTimePickerConfig, _config);
  }

  get config() {
    return this._config;
  }

  get defualtTimePickerConfig() {
    return {
      showDuration: true,
      timeFormat: 'H:i',
      step: 15,
      lang: {
        mins: this.t.instant('CalendarEvents.Timepicker.mins'),
        hr: this.t.instant('CalendarEvents.Timepicker.hr'),
        hrs: this.t.instant('CalendarEvents.Timepicker.hrs'),
      },
    };
  }

  get formatedDate() {
    return {
      start: this.form.get(this.startControl).value || null,
      end: this.form.get(this.endControl).value || null,
    };
  }

  constructor(private fb: FormBuilder, private t: TranslateService) {}

  ngOnInit(): void {
    this.createForm();
  }

  ngAfterViewInit() {
    this._initPairTimePicker();
  }

  private createForm() {
    if (!!this.form) {
      return;
    }
    this.form = this.fb.group({
      [this.startControl]: null,
      [this.endControl]: null,
    });
  }

  private getTemplateValue(selector: string) {
    return (this.timeContainer.querySelector(selector) as HTMLInputElement).value;
  }

  private _initPairTimePicker() {
    this.timeContainer = this.pairTimePicker.nativeElement;

    const start: any = $(this.timeContainer.querySelectorAll('.start'));
    const end: any = $(this.timeContainer.querySelectorAll('.end'));

    this.datePair = new Datepair(this.timeContainer, { anchor: 'start' });

    $(this.timeContainer).on('rangeSelected', (range) => {
      this.form.get(this.startControl).setValue(this.getTemplateValue('.start'));
      this.form.get(this.endControl).setValue(this.getTemplateValue('.end'));
    });

    $(this.timeContainer).on('rangeIncomplete', (range) => {
      this.form.get(this.startControl).setValue(this.getTemplateValue('.start'));
      this.form.get(this.endControl).setValue(this.getTemplateValue('.end'));
    });

    start[0].addEventListener('keyup', this.updateOnEnter.bind(this, this.startControl));
    end[0].addEventListener('keyup', this.updateOnEnter.bind(this, this.endControl));
  }

  updateOnEnter(controlname: string, event: any) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.updateControl(controlname);
      this.datePair.refresh();
    }
  }

  updateControl(controlName: string) {
    this.blurEvent.emit(controlName);
    this.form.get(controlName).markAsTouched();
    this.form.get(controlName).updateValueAndValidity();
  }
}
