import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  btnEmtyConfig = new ButtonConfig();
  @Input() type: string = null;
  @Input() loading: boolean = false;
  @Input() disabled: boolean = false;
  @Input() class = '';
  @Input() tourAnchorName?: string;
  _config: ButtonConfig;

  @Input('config')
  set config(config: ButtonConfig) {
    this._config = Object.assign(this.btnEmtyConfig, config);
  }

  get config() {
    return this._config;
  }

  @Output('onClick') onClick: EventEmitter<any> = new EventEmitter();
  @ViewChild('btn') btn: ElementRef;

  get ButtonClass() {
    return `${this[this.config.type]} ${this.class}`;
  }

  get BUTTON_SIZE() {
    let size = '';
    if (isNotNullOrUndefined(this.config.smSize)) {
      size += this.config.smSize === ButtonSize.SMALL ? 'btn-sm_sm' : '';
    } else {
      size += this.config?.size === ButtonSize.SMALL ? 'btn_sm' : '';
    }
    return size;
  }

  get FONT_WEIGHT() {
    return this.config?.bold ? 'bold' : 'font-400';
  }

  get SECONDARY_WHITE() {
    return `btn-empty_white ${this.FONT_WEIGHT} ${this.BUTTON_SIZE}`;
  }

  get SECONDARY_BLUE() {
    return `btn-empty_blue ${this.FONT_WEIGHT} ${this.BUTTON_SIZE}`;
  }

  get SECONDARY_GREY() {
    return `btn-empty_grey ${this.FONT_WEIGHT} ${this.BUTTON_SIZE}`;
  }

  get PRIMARY_BLUE() {
    return `btn-primary ${this.BUTTON_SIZE}`;
  }

  get PRIMARY_WHITE() {
    return `btn-white ${this.BUTTON_SIZE}`;
  }

  get PRIMARY_WHITE_BORDERED() {
    return `btn-white-bordered ${this.BUTTON_SIZE}`;
  }

  get PRIMARY_EMPTY() {
    return `btn-empty ${this.FONT_WEIGHT} ${this.BUTTON_SIZE}`;
  }

  get LIGHT_BLUE() {
    return `btn-light-blue ${this.FONT_WEIGHT} ${this.BUTTON_SIZE}`;
  }

  get PRIMARY_GREEN() {
    return `btn-success ${this.FONT_WEIGHT} ${this.BUTTON_SIZE}`;
  }

  get DISCARD_BUTTON() {
    return `btn-cancel ${this.FONT_WEIGHT} ${this.BUTTON_SIZE}`;
  }

  get DANGER_BUTTON() {
    return `btn-danger ${this.FONT_WEIGHT} ${this.BUTTON_SIZE}`;
  }

  get SECONDARY_RED() {
    return `btn-empty_red ${this.FONT_WEIGHT} ${this.BUTTON_SIZE}`;
  }

  get NEW_PRIMARY_BLUE() {
    return `btn-new-primary_blue ${this.FONT_WEIGHT} ${this.BUTTON_SIZE}`;
  }

  get NEW_PRIMARY_DARK_BLUE() {
    return `btn-new-primary_blue--dark ${this.FONT_WEIGHT} ${this.BUTTON_SIZE}`;
  }

  get NEW_SECONDARY_BLUE() {
    return `btn-new-empty_blue ${this.FONT_WEIGHT} ${this.BUTTON_SIZE}`;
  }

  get NEW_SECONDARY_NO_BORDER_BLUE() {
    return `btn-new-empty_blue btn-new-empty_blue--no-border ${this.FONT_WEIGHT} ${this.BUTTON_SIZE}`;
  }

  get NEW_RED() {
    return `btn-new-red ${this.FONT_WEIGHT} ${this.BUTTON_SIZE}`;
  }

  get TextStyles() {
    return {
      'ml-2': !!this.config?.prependSrc?.length || this.loading,
      'mr-2': !!this.config?.appendSrc?.length,
    };
  }

  constructor() {}

  ngOnInit(): void {}
}

export class ButtonConfig {
  size?: ButtonSize = ButtonSize.SMALL;
  smSize?: ButtonSize;
  text?: string = '';
  bold?: boolean = false;
  prependSrc?: string = null;
  appendSrc?: string = null;
  type: ButtonTypes = ButtonTypes.PRIMARY_BLUE;
}

export enum ButtonSize {
  SMALL,
  LARGE,
}

export enum ButtonTypes {
  SECONDARY_WHITE = 'SECONDARY_WHITE',
  SECONDARY_BLUE = 'SECONDARY_BLUE',
  SECONDARY_GREY = 'SECONDARY_GREY',
  SECONDARY_RED = 'SECONDARY_RED',
  PRIMARY_BLUE = 'PRIMARY_BLUE',
  LIGHT_BLUE = 'LIGHT_BLUE',
  PRIMARY_GREEN = 'PRIMARY_GREEN',
  PRIMARY_WHITE = 'PRIMARY_WHITE',
  PRIMARY_WHITE_BORDERED = 'PRIMARY_WHITE_BORDERED',
  DISCARD_BUTTON = 'DISCARD_BUTTON',
  DANGER_BUTTON = 'DANGER_BUTTON',
  PRIMARY_EMPTY = 'PRIMARY_EMPTY',
  NEW_PRIMARY_BLUE = 'NEW_PRIMARY_BLUE',
  NEW_PRIMARY_DARK_BLUE = 'NEW_PRIMARY_DARK_BLUE',
  NEW_SECONDARY_BLUE = 'NEW_SECONDARY_BLUE',
  NEW_SECONDARY_NO_BORDER_BLUE = 'NEW_SECONDARY_NO_BORDER_BLUE',
  NEW_RED = 'NEW_RED',
}
