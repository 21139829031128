<div #manageBox class="d-inline">
  <ng-container *ngIf="commentaries?.length">
    <div class="new-select-label mt-4 mb-4">
      {{ 'Commentaries.titleMany' | translate }}
    </div>

    <ul class="commentary__manage__list">
      <li
        *ngFor="let commentary of commentaries; let i = index; trackBy: trackCommentaries"
        [@fadeInOnEnter]="commentary"
        [@fadeOutOnLeave]
      >
        <div>
          <commentary-item
            (commentaryEvent)="emitCommentaryEvent($event)"
            [commentary]="commentary"
          ></commentary-item>
        </div>
      </li></ul
  ></ng-container>

  <div #commentaryInp class="commentary__manage__attachments d-flex">
    <text-attachments-input
      #textAttach
      [fileName]="'files'"
      [textName]="'note'"
      [form]="form"
      [config]="config"
      [attachmentsConfig]="attachmentsConfig"
    >
      <app-button
        #content
        [loading]="loading"
        [disabled]="loading"
        (onClick)="addCommentary()"
        [config]="{
          type: ButtonTypes.NEW_PRIMARY_DARK_BLUE,
          size: ButtonSize.LARGE,
          smSize: ButtonSize.SMALL,
          text: 'Commentaries.saveCommentary' | translate
        }"
      ></app-button
    ></text-attachments-input>
  </div>
</div>
