import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@shared/components/base.component';
import { Regex } from '@shared/configs/regex';
import { SettingsService, SettingEventType } from '@modules/settings/shared/services/settings.service';
import { HttpError } from '@shared/interfaces/error.interface';
import { Config } from '@shared/configs/config';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { IntilioCodes } from '@shared/enums/initilio-codes.enum';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';

@Component({
  selector: 'app-team-add-employee',
  templateUrl: './team-add-employee.component.html',
  styleUrls: ['./team-add-employee.component.scss']
})
export class TeamAddEmployeeComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  initEmail: string = '';
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  positionSelectOptions = [];

  constructor(
    public dialogRef: MatDialogRef<TeamAddEmployeeComponent>,
    public service: SettingsService,
    private changes: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
    this.setPositions();
  }

  setPositions() {
    Object.keys(UserRoles).forEach((key) => {
      this.positionSelectOptions.push({
        value: UserRoles[key],
        name: this.t.instant('ROLES.' + key)
      });
    });
  }

  createForm() {
    this.form = new FormGroup({
      email: new FormControl(this.initEmail, [
        Validators.required,
        Validators.pattern(Regex.email),
        Validators.maxLength(100)
      ]),
      role: new FormControl(null, [Validators.required]),
      hourlyRate: new FormControl('', [Validators.min(0)])
    });
  }

  submit() {
    this.form.markAllAsTouched();

    if (this.form.invalid || this.loading) {
      this.scrollToError();
      return;
    }
    this.loading = true;
    this.changes.detectChanges();
    this.service
      .inviteEmployee(this.form.value)
      .subscribe({
        next: this.onSuccessInviteEmployee.bind(this),
        error: this.onErrorInviteEmployee.bind(this)
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  onSuccessInviteEmployee() {
    this.s.success(this.t.instant('Settings.Team.employeeInvited'));
    this.service.emitter.emit({ type: SettingEventType.RELOAD_EMPLOYEES_LIST });
    this.form.reset();
    this.dialogRef.close();
  }

  onErrorInviteEmployee(e: HttpError) {
    switch (e.messageCode) {
      case IntilioCodes.EMPLOYE_ADDED:
        this.s.error(this.t.instant('Settings.Team.Errors.employeeAlreadyAdded'));
        break;
      case IntilioCodes.EMPLOYEE_EXISTS:
        this.s.error(this.t.instant('Settings.Team.Errors.employeeAlreadyExists'));
        break;
      default:
        this.s.error(this.t.instant('Settings.Team.Errors.errorInviteEmployee'));
        break;
    }
  }

  closeModal() {
    this.form.reset();
    this.dialogRef.close();
  }

  get currencySymbol() {
    return this.store.Employee?.company?.currency?.symbol;
  }

  errorMessages(name: string) {
    const messages = Config.validationMessages;
    const control = this.form.get(name);

    if (control?.errors?.maxlength?.requiredLength) {
      messages[GbxsoftErrorTypes.maxLength] = this.t.instant('FormErrors.maxLength', {
        number: control.errors?.maxlength?.requiredLength
      });
    }

    if (control?.errors?.min?.min || control?.errors?.min?.min === 0) {
      messages[GbxsoftErrorTypes.min] = this.t.instant('FormErrors.min', {
        number: control.errors?.min?.min.toString()
      });
    }

    return messages;
  }
}
