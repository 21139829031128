<div
  class="action-title-header"
  [ngClass]="{
    'action-title-header--sticky': sticky,
    'action-title-header--sticky--side-navigation': stickyWithSideNavigation,
    'action-title-header--double-height-mobile': doubleHeightOnMobile
  }"
  #header
>
  <div class="action-title-header__left-side">
    <h1 class="action-title-header-title" [innerHTML]="title"></h1>
  </div>
</div>
