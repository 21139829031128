import { NgSelectExtensionDirective } from '@shared/directives/ng-select-extension.directive';
import { Subscription } from 'rxjs';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { CheckPermission } from '@core/permissions/check-permission';
import { priceToPenny } from '@shared/helpers/price-to-penny.helper';
import { TranslateService } from '@ngx-translate/core';
import { TaskTypeList } from '@modules/protocols/shared/consts/task-type.list';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DiscountComponent } from '@shared/modules/tasks/components/task-components/discount/discount.component';
import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import { Config } from '@shared/configs/config';
import { StorageService } from '@core/services/storage.service';
import { TaskInfluence } from '@modules/protocols/shared/enums/task-influence.enum';
import { PricePipe } from '@shared/pipes/price.pipe';
import { ChangeSource } from '@modules/projects/shared/enums/change-source.enum';
import { Protocol } from '@modules/protocols/shared/models/protocol';
import { TASK_SIDENAV_FORM } from '@shared/modules/task-sidenav/const/task-sidenav-form';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { TaskType } from '@shared/enums/task-type.enum';
import { AttachmentType } from '@shared/components/text-attachments-input/enums/text-attachment-type.enum';
import { AttachmentService } from '@shared/services/attachment.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { getFileType } from '@shared/helpers/basic-file-type.helper';
import { TaskSelectionV2ChangeComponent } from '@shared/modules/tasks/components/task-selection-change-v2/task-selection-change-v2.component';
import { InputV2Component } from '@shared/components/input-v2/input-v2.component';
import { ProjectChangesSettlementStatus } from '@modules/projects/shared/enums/project-change-settlement-status.enum';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';

type InputEditorType = 'content' | 'internalNote';

@Component({
  selector: 'task-basic-edit-data',
  templateUrl: './task-basic-edit-data.component.html',
  styleUrls: ['./task-basic-edit-data.component.scss']
})
export class TaskBasicEditDataComponent implements OnInit, AfterViewInit, OnDestroy {
  TaskType = TaskType;

  @ViewChild('discountTempl') discountTempl: DiscountComponent;
  @ViewChild('financeTempl') financeTempl: TaskSelectionV2ChangeComponent;
  @ViewChild('daysTempl') daysTempl: TaskSelectionV2ChangeComponent;
  @ViewChild('taskTitleInput') taskTitleInput: InputV2Component;
  @ViewChild(NgSelectExtensionDirective) protocolList: NgSelectExtensionDirective;
  @Output('onSubmit') onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('editor') editor;
  @ViewChild('internalNoteEditor') internalNoteEditor;

  projectSub: Subscription;
  currentFinanceState: TaskInfluence;
  currentDateState: TaskInfluence;

  canProjectAdd: boolean = true;
  sources: any = [];

  taskList = TaskTypeList.map((i) => {
    i.name = this.t.instant(i.name);
    return i;
  });

  sub: Subscription;
  typeChange: Subscription = new Subscription();
  priceSub: Subscription = new Subscription();

  modules(inputType: InputEditorType) {
    return {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        ['link', 'image', 'video']
      ],
      imageHandler: {
        upload: (file) => {
          return new Promise((resolve, reject) => {
            return this.uploadFile(file, inputType, { resolve, reject });
          });
        },
        accepts: ['*']
      } as any,
      imageDropAndPaste: {
        handler: this.uploadImage(inputType).bind(this)
      }
    };
  }

  formats: string[] = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula',
    'image',
    'video'
  ];

  settlementsItems = [
    { name: 'Projects.ProjectSettlement.null', value: ProjectChangesSettlementStatus.CHANGE_SETTLEMENT_NONE },
    {
      name: 'Projects.ProjectSettlement.unsettled',
      value: ProjectChangesSettlementStatus.CHANGE_SETTLEMENT_NOT_SETTLED
    },
    {
      name: 'Projects.ProjectSettlement.settled',
      value: ProjectChangesSettlementStatus.CHANGE_SETTLEMENT_SETTLED
    }
  ].map((i) => {
    i.name = this.t.instant(i.name);
    return i;
  });

  get protocolURL() {
    const control = this.service.form.get(this.service.TASK_SIDENAV_FORM.projectId);
    return Config.API + '/protocol' + (control?.value ? `?filters[project.id][eq]=${control?.value}` : '');
  }

  errorMessages(name: string) {
    const messages = Config.validationMessages;
    const control = this.service.form.get(name);

    if (control?.errors?.maxlength?.requiredLength) {
      messages[GbxsoftErrorTypes.maxLength] = this.t.instant('FormErrors.maxLength', {
        number: control.errors?.maxlength?.requiredLength
      });
    }

    if (control?.errors?.minlength?.requiredLength) {
      messages[GbxsoftErrorTypes.minLength] = this.t.instant('FormErrors.minLength', {
        number: control.errors?.minlength?.requiredLength
      });
    }

    return Object.keys(messages)
      .filter((key) => Object.keys(control.errors || {}).includes(key))
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: messages[key] });
      }, {});
  }

  get SelectPriceValue() {
    if (!this.service?.task?.isPriceEstimated) {
      return this.service.financial[0];
    }

    if (this.service?.task?.price > 0) {
      return this.service.financial[1];
    } else if (this.service?.task?.price < 0) {
      return this.service.financial[2];
    } else if (this.service?.task?.price === 0) {
      return this.service.financial[3];
    } else {
      return this.service.financial[0];
    }
  }

  get SelectDaysValue() {
    const time = Number(this.service?.task?.additionalTime?.toString());
    if (!this.service?.task?.isAdditionalTimeEstimated) {
      return this.service.days[0];
    }

    if (time > 0) {
      return this.service.days[1];
    } else if (time < 0) {
      return this.service.days[2];
    } else if (time === 0) {
      return this.service.days[3];
    } else {
      return this.service.days[0];
    }
  }

  get InputDaysValue() {
    if (!this.service?.task?.isAdditionalTimeEstimated) {
      return null;
    }
    return Math.abs(!!this.service.task?.additionalTime ? this.service.task?.additionalTime : 0);
  }

  get InputPriceValue() {
    if (!this.service?.task?.isPriceEstimated) {
      return null;
    }
    return this.service?.task?.price !== undefined
      ? this.pricePipe.insertValueToInput(
          Math.abs(!!this.service?.task?.price ? this.service?.task?.price : 0),
          true
        )
      : null;
  }

  get isFinanceDisabled() {
    return (
      this.currentFinanceState === TaskInfluence.NO_CHANGES ||
      this.currentFinanceState === TaskInfluence.NO_ESTIMATION
    );
  }

  get isDateDisabled() {
    return (
      this.currentDateState === TaskInfluence.NO_CHANGES ||
      this.currentDateState === TaskInfluence.NO_ESTIMATION
    );
  }

  constructor(
    private t: TranslateService,
    private s: SnackBarService,
    public service: TaskSidenavService,
    public storage: StorageService,
    private pricePipe: PricePipe,
    private attachmentService: AttachmentService
  ) {
    this.setSources();
  }

  ngOnInit() {
    this.subscribeProjectUpdate();
  }

  ngAfterViewInit() {
    this.checkProjectPermissions();
    this.setFocusToTaskTitle();
    this.initialChangeTemplate(); //First Load
    const sub = this.service.form.get(TASK_SIDENAV_FORM.type).valueChanges.subscribe(() => {
      this.initialChangeTemplate(); // Type change
    });

    this.typeChange.add(sub);
  }

  initialChangeTemplate() {
    this.setFinanceAndDays();
    this.financeAndDaysChange();
    this.service.registerChangeTemplates({
      financeTemplate: this.financeTempl,
      daysTemplate: this.daysTempl
    });
  }

  imageHandler(imageDataUrl, type, imageData) {
    const file = imageData.toFile();
    this.service.attachmentsListRef.onFilesChange([file]);
  }

  setTypeActive(item) {
    this.service.form.get(this.service.TASK_SIDENAV_FORM.type).setValue(item.type);
    this.service.setTaskTypeFromSave();
    this.service.updateTaskType(item.type);
  }

  setFocusToTaskTitle() {
    this.taskTitleInput.inputv2.nativeElement.focus();
  }

  subscribeProjectUpdate() {
    this.projectSub = this.service.form
      .get(TASK_SIDENAV_FORM.projectId)
      .valueChanges.pipe(
        distinctUntilChanged(),
        tap((value) => this.service.form.get(TASK_SIDENAV_FORM.protocolId).setValue(null))
      )
      .pipe(
        debounceTime(0),
        tap(() => this.protocolList?.getItems())
      )
      .subscribe();
  }

  setSources() {
    this.sources = [];
    Object.values(ChangeSource)
      // Removing protocols: IS-359
      .filter((key) => key !== ChangeSource.SOURCE_PROTOCOL)
      .map((key) => {
        this.sources.push({
          id: key,
          name: this.t.instant('Projects.ProjectChangeSource.' + key)
        });
      });
  }

  checkProjectPermissions() {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.PROJECTS,
      action: 'ADD',
      objectCreatorId: []
    });
    this.canProjectAdd = ctrl.check();
  }

  isActiveTaskTypeTab(item: { name: string; type: TaskType }) {
    const val: TaskType = this.service.form.get(this.service.TASK_SIDENAV_FORM.type).value;
    return val === item.type || (val === TaskType.TYPE_COMPANY_TASK && item.type === TaskType.TYPE_TASK);
  }

  // setDiscount() {
  //   if (this.service.isConfigChange) {
  //     return;
  //   }
  //   this.discountTempl.discount.setValue(
  //     this.service.task?.discount ? Number(this.service.task?.discount) / 100 : 0,
  //   );
  // }

  financeAndDaysChange() {
    if (!this.service.isConfigChange && this.service.form?.value?.type !== TaskType.TYPE_CHANGE) {
      return;
    }

    this.priceSub?.unsubscribe();

    this.priceSub = new Subscription();
    const daysControl = this.daysTempl.form.get('input');
    const subDays = this.daysTempl.form.valueChanges.subscribe((value) => {
      if (daysControl.valid) {
        this.service.form
          .get(this.service.TASK_SIDENAV_FORM.additionalTime)
          .setValue(this.getInfluenceCount('daysTempl'));

        this.service.form
          .get(this.service.TASK_SIDENAV_FORM.isAdditionalTimeEstimated)
          .setValue(this['daysTempl'].form.value?.select?.id === TaskInfluence.NO_ESTIMATION ? 0 : 1);
      }
    });

    const financeControl = this.financeTempl.form.get('input');
    const subFinance = this.financeTempl.form.valueChanges.subscribe((value) => {
      if (financeControl.valid) {
        this.service.form
          .get(this.service.TASK_SIDENAV_FORM.price)
          .setValue(priceToPenny(this.getInfluenceCount('financeTempl')));
        this.service.form
          .get(this.service.TASK_SIDENAV_FORM.isPriceEstimated)
          .setValue(this['financeTempl'].form.value?.select?.id === TaskInfluence.NO_ESTIMATION ? 0 : 1);
      }
    });

    this.priceSub.add(subDays).add(subFinance);
  }

  onEnter(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.onSubmit.emit();
    }
  }

  setFinanceAndDays() {
    if (!this.service.isConfigChange) {
      return;
    }
    this.currentFinanceState = this.SelectPriceValue.id;
    this.currentDateState = this.SelectDaysValue.id;

    this.financeTempl.form.setValue({
      select: this.SelectPriceValue,
      input: this.InputPriceValue
    });

    this.daysTempl.form.setValue({
      select: this.SelectDaysValue,
      input: this.InputDaysValue
    });
  }

  influenceChangeEvent($event, templateName: string, stateName: string) {
    switch ($event.id) {
      case TaskInfluence.NO_CHANGES:
        this[templateName].form.get('input').setValue(templateName === 'financeTempl' ? '0.00' : '0');
        break;
      case TaskInfluence.NO_ESTIMATION:
        this[templateName].form.get('input').setValue(null);
        break;
      default:
        if (this[templateName].form.get('input').value === 0) {
          this[templateName].form.get('input').setValue('');
        }
        setTimeout(() => this[templateName].input?.inputElement?.focus(), 50);
        break;
    }
    this[stateName] = $event.id;
  }

  getInfluenceCount(templateName: string) {
    const select = this[templateName]?.form.get('select').value;
    const input = this[templateName]?.form.get('input').value;
    let value: any = Math.abs(input);
    switch (select.id) {
      case TaskInfluence.PLUS:
        break;
      case TaskInfluence.MINUS:
        value = value * -1;
        break;
      case TaskInfluence.NO_CHANGES:
        value = 0;
      case TaskInfluence.NO_ESTIMATION:
        value = '';
        break;
    }
    return value;
  }

  responseTransform(resp) {
    const protocolList = resp.records.map((i) => new Protocol(i.protocol));
    return protocolList;
  }

  isControlInvalid(name: string) {
    return this.service.form?.get(name)?.touched && this.service.form?.get(name)?.invalid;
  }

  // #region upload Section
  uploadImage(inputType: InputEditorType) {
    return (imageDataUrl, type, imageData) => {
      const file = imageData.toFile();
      this.uploadFile(file, inputType);
    };
  }

  uploadFile(file: File, inputType: InputEditorType, opt: { resolve?: any; reject?: any } = {}) {
    const { resolve, reject } = opt;
    if (getFileType(file) === AttachmentType.FILE) {
      this.s.error(this.t.instant('ProjectCreator.imagesAllowed'));
      reject('Unsupported');
      return;
    }
    const quill = inputType === 'content' ? this.editor.quillEditor : this.internalNoteEditor.quillEditor;
    let index = (quill.getSelection() || {}).index;
    if (index === undefined || index < 0) index = quill.getLength();
    let data = {};

    this.attachmentService
      .uploadAttachment(
        {
          objectType: 'wysiwyg-content',
          files: [file]
        },
        data
      )
      .subscribe({
        next: (data) => {
          let url = `${Config.PRIVATE_ATTACHMENT}${data[0].file}`;
          if (resolve) {
            resolve(url);
          } else {
            quill.insertEmbed(index, 'image', url);
            this.service.form.get(this.service.TASK_SIDENAV_FORM[inputType]).setValue(quill.root.innerHTML);
          }
          setTimeout(() => quill.setSelection(index + 1, 0), 0);
        },
        error: () => {
          reject ? reject('Unsupported') : null;
        }
      });
  }

  // #endregion

  ngOnDestroy() {
    // this.subDiscountChange?.unsubscribe();
    this.projectSub?.unsubscribe();
    this.priceSub?.unsubscribe();
    this.typeChange?.unsubscribe();
  }
}
