<div class="phone-control">
  <form [formGroup]="form">
    <country-picker-v2
      #countryPickerComponent
      [phoneFlagName]="phoneFlagName"
      [form]="form"
      [disabled]="disabled"
      [emitter]="changesEmitter"
    >
    </country-picker-v2>
    <input-v2
      class="number-control"
      formControlName="lastName"
      type="number"
      [placeholder]="inputPlaceholder ? inputPlaceholder : ''"
      formControlName="{{ phoneName }}"
      [disabled]="disabled"
      #input
    >
    </input-v2>
  </form>
</div>
