<form [formGroup]="form">
  <div class="mb-3">
    <div class="new-select-label">{{ getLabelOrPlaceholder('label', formFields.Address) }}</div>
    <input-v2
      #autocomplete
      class="text-control"
      [formControlName]="config.addressControlName"
      [errMessages]="errorMessages(config.addressControlName)"
      [placeholder]="getLabelOrPlaceholder('placeholder', formFields.Address)"
    >
    </input-v2>
  </div>

  <div class="row">
    <div class="col-md-4 col-lg-12 col-xl-4 mb-3">
      <div>
        <div class="new-select-label">{{ getLabelOrPlaceholder('label', formFields.PostalCode) }}</div>
        <input-v2
          class="text-control"
          [formControlName]="config.postalCodeControlName"
          [errMessages]="errorMessages(config.postalCodeControlName)"
          [placeholder]="getLabelOrPlaceholder('placeholder', formFields.PostalCode)"
        >
        </input-v2>
      </div>
    </div>
    <div class="col-md-4 col-lg-12 col-xl-4 mb-3">
      <div>
        <div class="new-select-label">
          {{ getLabelOrPlaceholder('label', formFields.Town) }}
        </div>
        <input-v2
          class="text-control"
          [formControlName]="config.townControlName"
          [errMessages]="errorMessages(config.townControlName)"
          [placeholder]="getLabelOrPlaceholder('placeholder', formFields.Town)"
        >
        </input-v2>
      </div>
    </div>
    <div class="col-md-4 col-lg-12 col-xl-4 mb-3">
      <div>
        <div class="new-select-label">{{ getLabelOrPlaceholder('label', formFields.Country) }}</div>
        <ng-select
          [compareWith]="compareCountries"
          [formControlName]="config.countryControlName"
          [items]="countrySelectOptions"
          [bindLabel]="'text'"
          [bindValue]="'id'"
          [placeholder]="getLabelOrPlaceholder('placeholder', formFields.Country)"
          class="new-select"
        ></ng-select>
      </div>
    </div>
  </div>
</form>
