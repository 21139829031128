<div class="settings settings-content">
  <div class="team-content">
    <ng-container *ngFor="let employee of employees">
      <app-team-card
        [role]="'ROLES.' + employee.role | translate"
        [active]="employee.active"
        [previewName]="employee.userPerson.previewName"
        [salary]="employee?.hourlyRate ? employee.hourlyRate + companyCurrency : '-'"
        [address]="employee.userPerson.email"
        (emitAction)="handleAction($event, employee)"
      ></app-team-card>
    </ng-container>
  </div>
</div>
