import { ContactType } from '@modules/contacts/shared/enums/contact-type.enum';
export class BaseContactModel {
  id?: number;
  name: string;
  type?: ContactType;
  firstName?: string;
  lastName?: string;
  companyName?: string;

  // Custom fields
  fullName?: string;

  constructor(contact?: BaseContactModel) {
    contact ? Object.assign(this, contact) : null;
    this.setFullName();
  }

  setFullName(): void {
    switch (this.type) {
      case ContactType.PERSONAL:
        this.firstName || this.lastName
          ? (this.fullName = [this.firstName, this.lastName].filter(Boolean).join(' '))
          : (this.fullName = this.name);
        break;
      case ContactType.COMPANY:
        this.fullName = this.companyName;
        break;
      default:
        this.firstName || this.lastName
          ? (this.fullName = [this.firstName, this.lastName].filter(Boolean).join(' '))
          : (this.fullName = this.name);
        break;
    }
  }
}
