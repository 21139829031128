<list-chips *ngIf="chipsView"></list-chips>
<div class="new-filters-modal" [hidden]="chipsView">
  <div class="title__wrapper">
    <span class="title">{{ 'List.Filters.filters' | translate }}</span>
    <svg-icon class="clear-icon" [src]="'/assets/img/close-icon.svg'" (click)="cancel()"></svg-icon>
  </div>
  <div class="rows__wrapper">
    <div class="new-filters-modal__row">
      <list-single-filter-select-v2
        class="w-100"
        [name]="'ProjectTasks.Filters.createdBy' | translate"
        [iconUrl]="'/assets/img/ic-person-circle-outline.svg'"
        [form]="service?.filtersForm"
        [controlName]="'[creator][eq]'"
        (change)="onChangeFilter()"
        [searchGetParams]="'search'"
        [requiredIdUrl]="Config.API + '/employee/search'"
        [valueTransform]="creatorName.bind(this)"
        [multiple]="true"
        (selectedValues)="handleSelectedValues($event, '[creator][eq]')"
      ></list-single-filter-select-v2>

      <list-single-filter-select-v2
        class="w-100"
        #assigneeFilter
        [name]="'ProjectTasks.Filters.assigneeTo' | translate"
        [iconUrl]="'/assets/img/ic-person-circle-outline.svg'"
        [form]="service?.filtersForm"
        [multiple]="true"
        [controlName]="'[assigned][in][]'"
        (change)="onChangeFilter()"
        [responseTransform]="responseTransformAssignee.bind(this)"
        [searchGetParams]="'query'"
        [requiredIdUrl]="Config.API + '/project/' + service?.data?.projectId + '/tasks-employees'"
        [valueTransform]="creatorName.bind(this)"
        (selectedValues)="handleSelectedValues($event, '[assigned][in][]')"
      ></list-single-filter-select-v2>
    </div>

    <div class="new-filters-modal__row">
      <list-single-filter-select-v2
        class="w-100"
        [name]="'ProjectTasks.Filters.status' | translate"
        [iconUrl]="'/assets/img/ic-checkmark-circle-outline.svg'"
        [form]="service?.filtersForm"
        [localItems]="taskStatuses"
        [multiple]="true"
        [bindValue]="'id'"
        [bindLabel]="'name'"
        [controlName]="'[a-status][in][]'"
        (change)="onChangeFilter()"
        (selectedValues)="handleSelectedValues($event, '[a-status][in][]')"
      ></list-single-filter-select-v2>

      <list-single-filter-select-v2
        class="w-100"
        [name]="'MyWork.Filters.type' | translate"
        [iconUrl]="'/assets/img/ic-checkmark-circle-outline.svg'"
        [form]="service?.filtersForm"
        [localItems]="taskTypes"
        [bindValue]="'id'"
        [bindLabel]="'name'"
        [controlName]="'[a-type][eq]'"
        [multiple]="true"
        (change)="onChangeFilter()"
        (selectedValues)="handleSelectedValues($event, '[a-type][eq]')"
      ></list-single-filter-select-v2>
    </div>

    <div class="new-filters-modal__row">
      <list-single-filter-select-v2
        #tagFilter
        class="w-100"
        [name]="'ProjectTasks.Filters.tags' | translate"
        [iconUrl]="'/assets/img/ic-tag.svg'"
        [form]="service?.filtersForm"
        [controlName]="'[tag-name][in][]'"
        [multiple]="true"
        (change)="onChangeFilter()"
        [searchGetParams]="'query'"
        [bindValue]="'name'"
        [bindLabel]="'name'"
        [requiredIdUrl]="Config.API + '/tag/type?type=task-tag&projectId=' + listTaskService?.projectId"
        (selectedValues)="handleSelectedValues($event, '[tag-name][in][]')"
      ></list-single-filter-select-v2>
    </div>
  </div>

  <list-single-filter-toggle
    [name]="'ProjectTasks.showCompleted' | translate"
    [form]="service?.filtersForm"
    [controlName]="'[show_completed]'"
    (change)="onChangeFilter()"
  ></list-single-filter-toggle>

  <div class="buttons">
    <list-single-clear-filters-v2
      [form]="service?.filtersForm"
      (change)="onChangeFilter()"
      [shouldShowClearFilters]="shouldShowClearFilters.bind(this)"
      [clearFilters]="clearFilters.bind(this)"
    >
    </list-single-clear-filters-v2>
    <div class="buttons__inside">
      <app-button
        [type]="'button'"
        (onClick)="cancel()"
        [config]="{
          size: ButtonSize.LARGE,
          smSize: ButtonSize.SMALL,
          type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
          text: 'DATERANGEPICKER.cancelBtn' | translate
        }"
      ></app-button>

      <app-button
        [type]="'button'"
        [disabled]="loading"
        [loading]="loading"
        (onClick)="submit()"
        [config]="{
          size: ButtonSize.LARGE,
          smSize: ButtonSize.SMALL,
          type: ButtonTypes.NEW_PRIMARY_BLUE,
          text: 'DATERANGEPICKER.acceptBtn' | translate,
          prependSrc: './assets/img/tick-icon.svg'
        }"
      ></app-button>
    </div>
  </div>
</div>
