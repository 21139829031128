import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { BasePanelService } from '@shared/modules/base-panel/services/base-panel.service';
import { WindowHelper } from '@shared/helpers/window.helper';

@Component({
  selector: 'title-header-v2',
  templateUrl: './title-header-v2.component.html',
  styleUrls: ['./title-header-v2.component.scss']
})
export class TitleHeaderV2Component implements OnInit, AfterViewInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  WindowHelper = WindowHelper;

  @Input() title: string;
  @Input() sticky?: boolean = false;
  @Input() doubleHeightOnMobile: boolean = false;
  @Input() stickyWithSideNavigation?: boolean = false;
  @ViewChild('header') header: ElementRef;

  constructor(private basePanelService: BasePanelService) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.header.nativeElement.style.right = this.basePanelService.scrollbarWidth + 'px';
    });
  }
}
