import * as moment from 'moment';
import { combineLatest, Subscription, throwError, Observable, of } from 'rxjs';
import { startWith, tap, catchError } from 'rxjs/operators';
import { HttpError } from '@shared/interfaces/error.interface';
import { EventEmitter, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EventCycleList } from '../consts/event-cycle.list';
import { EventReminderFullDayList, EventReminderNonFullDayList } from '../consts/event-reminder.list';
import { EVENT_SIDENAV_FORM } from '../enums/event-sidenav-form.enum';
import { EventViewState } from '../enums/event-view-state.enum';
import { SnackBarService } from '@core/services/snackbar.service';
import { Config } from '@shared/configs/config';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';
import { MatDialog } from '@angular/material/dialog';
import { EventDiscardDialogComponent } from '../components/event-sidenav/components/event-discard-dialog/event-discard-dialog.component';
import { IEventAction } from '../interfaces/event-action.interface';
import { EventAction } from '../enums/event-action.enum';
import { CalendarEvent } from '../models/calendar-event.model';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DateRangePickerComponent } from '@shared/components/date-rangepicker/date-rangepicker.component';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { Task } from '@shared/models/task.model';
import { TaskService } from '@shared/modules/tasks/services/task-service';
import { Participant } from '../models/participant.model';
import { EventProtocolsController } from '../controllers/event-protocols.controller';
import { IEventExtraCreation } from '@shared/modules/event-sidenav/interfaces/event-extra-creation.interface';
import { CalendarApiService } from '@modules/calendar/shared/services/calendar-api.service';
import { truncateContent } from '@shared/helpers/truncate.helper';
import { endDateHelper } from '@shared/helpers/date-format.helper';
import { IntilioCodes } from '@shared/enums/initilio-codes.enum';

@Injectable()
export class EventSidenavService {
  EVENT_SIDENAV_FORM = EVENT_SIDENAV_FORM;
  form: FormGroup;
  loading: boolean = false;
  viewState: EventViewState = null;
  loadingEvent: boolean = false;
  savedFormValues: Record<EVENT_SIDENAV_FORM, string>;
  subFullDay: Subscription;
  participants: NgSelectComponent;
  daterangepicker: DateRangePickerComponent;
  creatorContact: Contact;
  calendarEvent: CalendarEvent = null;
  task: Task = null;
  projectID: number = null;
  participantsList: number[] = [];
  subArray: Subscription;
  assignedParticipants: any[] = [];
  eventProtocolCtrl: EventProtocolsController = new EventProtocolsController();

  readonly CalendarEventEmitter: EventEmitter<IEventAction> = new EventEmitter();

  EventCycleList = EventCycleList.map((i) => {
    i.label = this.t.instant(i.label);
    return i;
  });

  EventReminderFullDayList = EventReminderFullDayList.map((i) => {
    i.label = this.t.instant(i.label);
    return i;
  });

  EventReminderNonFullDayList = EventReminderNonFullDayList.map((i) => {
    i.label = this.t.instant(i.label);
    return i;
  });

  get assignedEmails() {
    const items =
      this.participants?.selectedItems
        .filter((i) => !!(i.value as any)?.newItem)
        .map((i) => (i.value as any)?.id) || [];
    return items;
  }

  get assignedIds() {
    const items =
      this.participants?.selectedItems
        .filter((i) => !(i.value as any)?.newItem)
        .map((i) => (i.value as any)?.id) || [];
    return items;
  }

  get terms() {
    const helperStartDate = this.form.get(this.EVENT_SIDENAV_FORM.helperStartDate).value;
    const helperEndDate = this.form.get(this.EVENT_SIDENAV_FORM.helperEndDate).value;

    const helperStartTime = this.form.get(this.EVENT_SIDENAV_FORM.helperStartTime).value;
    const helperEndTime = this.form.get(this.EVENT_SIDENAV_FORM.helperEndTime).value;

    // 1. Make vars Moment
    let termStartMoment: any = moment(helperStartDate, Config.DATE_FORMAT_DOTS);
    let termEndMoment: any = moment(helperEndDate, Config.DATE_FORMAT_DOTS);
    let termStart = '';
    let termEnd = '';

    // 2. Set vars String
    if (!!this.form.get(this.EVENT_SIDENAV_FORM.isFullDay).value) {
      termStart = termStartMoment.startOf('day').format(Config.DATE_SERVER);
      termEnd = termEndMoment.endOf('day').format(Config.DATE_SERVER);
    } else {
      termStart = `${termStartMoment.format(Config.DATE_SERVER_YYMMDD)} ${helperStartTime}:00`;
      termEnd = `${termEndMoment.format(Config.DATE_SERVER_YYMMDD)} ${helperEndTime}:00`;
    }

    return {
      termStart: moment(termStart, Config.DATE_SERVER).isValid() ? termStart : null,
      termEnd: moment(termEnd, Config.DATE_SERVER).isValid() ? termEnd : null
    };
  }

  get initialFormValues() {
    return {
      [EVENT_SIDENAV_FORM.calendarId]: null,
      [EVENT_SIDENAV_FORM.projectId]: null,
      [EVENT_SIDENAV_FORM.name]: null,
      [EVENT_SIDENAV_FORM.isFullDay]: false,
      [EVENT_SIDENAV_FORM.reminderTypes]: [],
      [EVENT_SIDENAV_FORM.cycleType]: null,
      [EVENT_SIDENAV_FORM.emails]: [],
      [EVENT_SIDENAV_FORM.contactsIds]: [],
      [EVENT_SIDENAV_FORM.description]: null,
      [EVENT_SIDENAV_FORM.address]: null,
      [EVENT_SIDENAV_FORM.termStart]: null,
      [EVENT_SIDENAV_FORM.termEnd]: null,
      [EVENT_SIDENAV_FORM.helperStartDate]: moment().format(Config.DATE_SERVER),
      [EVENT_SIDENAV_FORM.helperEndDate]: moment().format(Config.DATE_SERVER),
      [EVENT_SIDENAV_FORM.helperStartTime]: '08:00',
      [EVENT_SIDENAV_FORM.helperEndTime]: '08:30'
    };
  }

  get SystemCalendars() {
    return `${Config.API}/calendar?onlySystemCalendars=1`;
  }
  get ContactsUrl() {
    return `${Config.API}/contact/search?requiredMyself=1&emailsOnly=1`;
  }

  get isPreview() {
    return this.viewState === EventViewState.PREVIEW;
  }

  get isEdit() {
    return this.viewState === EventViewState.EDIT;
  }

  get isCreation() {
    return this.viewState === EventViewState.CREATE;
  }

  get currentFormValues(): Record<EVENT_SIDENAV_FORM, string> {
    return Object.assign({}, this.form.value);
  }

  get isWholeDaySelected() {
    return !!this.form.get(this.EVENT_SIDENAV_FORM.isFullDay).value;
  }

  get reminderItems() {
    return this.isWholeDaySelected ? this.EventReminderFullDayList : this.EventReminderNonFullDayList;
  }

  get formValues() {
    const model = Object.assign({}, this.form.value);

    model.termEnd = endDateHelper(model.termEnd);

    delete model.helperStartDate;
    delete model.helperEndDate;

    delete model.helperStartTime;
    delete model.helperEndTime;

    model.reminderTypes = !model.reminderTypes?.length ? [] : model.reminderTypes;
    model.contactsIds = this.assignedIds.filter((id: number) => id !== this.creatorContact?.id);
    model.emails = this.assignedEmails;
    model.protocolsIds = this.eventProtocolCtrl.protocolsIds;
    return model;
  }

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private t: TranslateService,
    private s: SnackBarService,
    public sideService: SidenavService,
    private api: CalendarApiService,
    private taskService: TaskService
  ) {
    this.createForm();
    this.createValidators();
  }

  createForm() {
    this.form = this.fb.group(this.initialFormValues);
  }

  createCustomTag(name: string): any {
    return { id: name, name, newItem: true, fullName: name };
  }

  updateTime($event) {
    if (this.form.get(EVENT_SIDENAV_FORM.isFullDay).value) return;

    const startTime = this.form.get(EVENT_SIDENAV_FORM.helperStartTime);
    const endTime = this.form.get(EVENT_SIDENAV_FORM.helperEndTime);

    const startTimeMoment = moment(startTime.value, Config.TIME);
    const endTimeMoment = moment(endTime.value, Config.TIME);

    const isBefore = endTimeMoment.isBefore(startTimeMoment);

    if ($event === EVENT_SIDENAV_FORM.helperEndTime) {
      isBefore ? startTime.setValue(endTime.value) : null;
    } else {
      isBefore ? endTime.setValue(startTime.value) : null;
    }

    startTime.updateValueAndValidity();
    endTime.updateValueAndValidity();
  }

  //#region Validators
  createValidators() {
    this.createValidator(EVENT_SIDENAV_FORM.name, [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(200)
    ]);

    this.createValidator(EVENT_SIDENAV_FORM.termStart, [Validators.required]);
    this.createValidator(EVENT_SIDENAV_FORM.termEnd, [Validators.required]);

    this.setHelperTimeValidators([Validators.required]);
  }

  setHelperTimeValidators(validators: ValidatorFn[]) {
    this.createValidator(EVENT_SIDENAV_FORM.helperStartTime, validators);
    this.createValidator(EVENT_SIDENAV_FORM.helperEndTime, validators);
  }

  createValidator(name: string, validators: ValidatorFn[]) {
    const control = this.form.get(name);
    control.setValidators(validators);
    control.updateValueAndValidity({ emitEvent: false });
  }

  controlInvalid(name: string) {
    const control = this.form.get(name);
    return control.touched && control.invalid;
  }

  //#endregion

  setCreatorContact(creator: Contact) {
    if (!creator) {
      this.creatorContact = null;
      return;
    }
    creator.disabled = true;
    this.creatorContact = creator;
  }

  setCreatorAsParticipant(creator: Contact) {
    this.setCreatorContact(creator);
    let participants = (this.creatorContact.id ? [this.creatorContact.id] : []).concat(this.participantsList);
    participants = [...new Set(participants)]; // set unique id`s
    this.form.get(EVENT_SIDENAV_FORM.contactsIds).setValue(participants);
    this.setFromHistory();
  }

  setParticipants(participants: Participant[]) {
    this.setCreatorContactFromParticipants(participants);
    const participantContacts = participants.filter((i) => !!i.contact).map((i) => i.contact) || [];
    const participantEmails = participants.filter((i) => !i.contact) || [];

    this.assignedParticipants = participantContacts?.concat(
      participantEmails?.map((i) => this.createCustomTag(i.email))
    );

    this.form.get(EVENT_SIDENAV_FORM.contactsIds).setValue(this.assignedParticipants.map((i) => i.id));
  }

  setCreatorContactFromParticipants(participants: Participant[]) {
    const findIndex = participants.findIndex(
      (i) => i?.contact?.employee?.id === this.calendarEvent?.creator?.id
    );
    this.setCreatorContact(findIndex > -1 ? participants[findIndex]?.contact : null);
  }

  setPreviewTerms(start, end) {
    this.form.get(EVENT_SIDENAV_FORM.helperStartDate).setValue(start);
    this.form.get(EVENT_SIDENAV_FORM.helperEndDate).setValue(end);

    this.form
      .get(EVENT_SIDENAV_FORM.helperStartTime)
      .setValue(moment(start, Config.DATE_SERVER).format(Config.TIME));

    this.form
      .get(EVENT_SIDENAV_FORM.helperEndTime)
      .setValue(moment(end, Config.DATE_SERVER).format(Config.TIME));

    this.form.get(EVENT_SIDENAV_FORM.termStart).setValue(start);
    this.form.get(EVENT_SIDENAV_FORM.termEnd).setValue(end);
  }

  setProtocols() {
    this.eventProtocolCtrl.set(this.calendarEvent?.protocols);
  }

  subscribeUpdateFullDay() {
    this.subFullDay = this.form
      .get(this.EVENT_SIDENAV_FORM.isFullDay)
      .valueChanges.subscribe((checked: boolean) => this.onFullDayChange(checked));
  }

  onFullDayChange(checked: boolean) {
    this.form.get(this.EVENT_SIDENAV_FORM.reminderTypes).setValue([this.reminderItems[0].id]);
    this.setHelperTimeValidators(!checked ? [Validators.required] : []);
  }

  setFromHistory() {
    this.savedFormValues = this.currentFormValues;
  }

  subscribeHelperDate() {
    const $helperStartDate = this.form
      .get(EVENT_SIDENAV_FORM.helperStartDate)
      ?.valueChanges.pipe(startWith(moment().format(Config.DATE_SERVER)));

    const $helperEndDate = this.form
      .get(EVENT_SIDENAV_FORM.helperEndDate)
      ?.valueChanges.pipe(startWith(moment().format(Config.DATE_SERVER)));

    const $helperStartTime = this.form
      .get(EVENT_SIDENAV_FORM.helperStartTime)
      ?.valueChanges.pipe(startWith('08:00'));

    const $helperEndTime = this.form
      .get(EVENT_SIDENAV_FORM.helperEndTime)
      ?.valueChanges.pipe(startWith('08:30'));

    const $isFullDay = this.form.get(EVENT_SIDENAV_FORM.isFullDay)?.valueChanges.pipe(startWith(false));

    const subs = [$helperStartDate, $helperEndDate, $helperStartTime, $helperEndTime, $isFullDay];
    this.subArray = combineLatest(subs).subscribe(() => this.setEventTerm());
  }

  setEventTerm() {
    this.form.get(EVENT_SIDENAV_FORM.termStart).setValue(this.terms.termStart);
    this.form.get(EVENT_SIDENAV_FORM.termStart).markAsTouched();
    this.form.get(EVENT_SIDENAV_FORM.termEnd).setValue(this.terms.termEnd);
    this.form.get(EVENT_SIDENAV_FORM.termEnd).markAsTouched();
  }

  compareFormValues(
    stateOld: Record<EVENT_SIDENAV_FORM, string>,
    stateNew: Record<EVENT_SIDENAV_FORM, string>
  ) {
    return JSON.stringify(stateOld) === JSON.stringify(stateNew);
  }

  setInitialData(options: { extra: IEventExtraCreation; viewState }) {
    this.resetEventSidenav();
    this.resetDefaultValuesWithinSidenav();

    this.loadingEvent = true;
    this.viewState = options?.viewState || null;

    this.setTitleFromUrl(options);
    this.setProjectId(Number(options?.extra?.projectId));

    this.loadingEvent = false;

    this.subscribeUpdateFullDay();
    this.setFullDayFromUrl(options);

    this.subscribeHelperDate();

    this.setTermsFromUrl(options);

    this.getDetails(options);
    this.setAdditionalParticipant(options);

    //Set History on end
    this.setFromHistory();
  }

  setAdditionalParticipant(options) {
    this.participantsList = this.participantsList.concat(
      options?.extra?.participantIds ? options?.extra?.participantIds : []
    );
  }

  setFullDayFromUrl(options) {
    if (options?.extra?.isFullDay) {
      this.form.get(EVENT_SIDENAV_FORM.isFullDay).setValue(options.extra.isFullDay);
    }
  }

  setTermsFromUrl(options) {
    if (options?.extra?.termStart && options?.extra?.termEnd) {
      this.setPreviewTerms(options?.extra?.termStart, options?.extra?.termEnd);
    } else {
      this.setPreviewTerms(moment().format(Config.DATE_SERVER), moment().format(Config.DATE_SERVER));
    }
  }

  setTitleFromUrl(options) {
    if (options?.extra?.title) {
      this.form.get(EVENT_SIDENAV_FORM.name).setValue(options.extra.title);
    }
  }

  setCalendarId(id: number) {
    this.form.get(EVENT_SIDENAV_FORM.calendarId).patchValue(id, { onlySelft: true });
  }

  setProjectId(id: number) {
    if (!id) return;
    this.projectID = id || null;
    this.form.get(EVENT_SIDENAV_FORM.projectId).patchValue(id, { onlySelft: true });
  }

  getGoogleEventRequest(googleEventId: string): Observable<CalendarEvent> {
    this.loadingEvent = true;
    return this.api.getGoogleEvent(googleEventId);
  }

  getEventRequest(id: number): Observable<CalendarEvent> {
    this.loadingEvent = true;
    return this.api.getEvent(id);
  }

  getTaskRequest(id: number): Observable<Task> {
    this.loadingEvent = true;
    return this.taskService.getTask(id);
  }

  getDetails(options) {
    this.isCreation ? this.getCreationDetails(options) : this.getPreviewDetails(options);
  }

  getCreationDetails(options) {
    options.extra?.taskId ? this.getTask(options.extra?.taskId) : null;
  }

  // #region Event For Task
  getTask(id: number) {
    this.getTaskRequest(id)
      .subscribe((task: Task) => this.setEventTask(task))
      .add(() => {
        this.loadingEvent = false;
      });
  }

  setEventTask(task: Task) {
    this.task = task;
    this.setProjectId(this.task?.project?.id);
    this.form.get(EVENT_SIDENAV_FORM.name).setValue(this.task.description);
    this.form.get(EVENT_SIDENAV_FORM.description).setValue(truncateContent(this.task.content));
    this.setFromHistory();
  }

  //#endregion

  getPreviewDetails(options) {
    let source;
    //todo case for download preview google calendar event
    if (options.isGoogleEvent) {
      source = this.getGoogleEventRequest(options.googleEventId);
    } else {
      source = options.event ? of(new CalendarEvent(options.event)) : this.getEventRequest(options.eventId);
    }

    source
      .pipe(
        tap((event: CalendarEvent) => this.initPreviewState(event)),
        catchError((error) => {
          Config.DEBUG ? console.log(error) : null;
          if (error.messageCode === 'intilio_81') {
            this.s.error(this.t.instant('CalendarEvents.calendarNotIntegrated'));
            this.closeEventSidenav();
          }
          return of(error);
        })
      )
      .subscribe()
      .add(() => {
        this.loadingEvent = false;
      });
  }

  initPreviewState(event: CalendarEvent) {
    this.calendarEvent = Object.assign({}, event);
    this.initPreviewFormData();
    this.setFromHistory();
  }

  initPreviewFormData() {
    this.setExistingData();
    this.manuallySetData();
  }

  setExistingData() {
    Object.keys(this.calendarEvent).forEach((key: string) => {
      const ctrl = this.form.get(key);
      !!ctrl ? ctrl.setValue(this.calendarEvent[key]) : null;
    });
  }

  manuallySetData() {
    this.setCalendarId(this.calendarEvent?.calendarId || null);
    this.setProjectId(this.calendarEvent?.project?.id);
    this.setParticipants(this.calendarEvent.participants);
    this.setPreviewTerms(this.calendarEvent.termStart, this.calendarEvent.termEnd);
    this.setProtocols();
  }

  // #region Create Event
  updateEvent(protocolsUpdate: boolean = false) {
    if ((this.isEdit && !this.daterangepicker?.dateControl?.value) || this.form.invalid || this.loading) {
      return;
    }
    this.loading = true;
    const data = Object.assign({}, this.formValues);

    let source;
    if (this.calendarEvent.isGoogleEvent) {
      data['eventId'] = this.calendarEvent.googleEventId;
      // this.calendarEvent.googleEventType === GoogleEventType.outOfOffice?  data['description'] = null : null;
      source = this.api.editGoogleEvent(data);
    } else {
      source = this.api.editEvent(data, this.calendarEvent.id);
    }

    return source.pipe(
      tap((event: any) => this.successEditEvent(event, protocolsUpdate)),
      catchError((err: HttpError) => {
        this.errorCreateEvent(err);
        return throwError(err);
      })
    );
  }

  successEditEvent(event: CalendarEvent, protocolsUpdate: boolean) {
    this.loading = false;
    this.CalendarEventEmitter.emit({ action: EventAction.ACTION_EDIT, data: { event } });
    this.setCreatorContact(this.creatorContact);
    !protocolsUpdate ? this.s.success(this.t.instant('CalendarEvents.eventEditSuccess')) : null;
  }

  errorEditEvent(err: HttpError) {
    this.loading = false;
    switch (err.messageCode) {
      case IntilioCodes.WRONG_DATES:
        this.s.error(this.t.instant('CalendarEvents.eventDateTimeError'));
        break;
      default:
        this.s.error(this.t.instant('CalendarEvents.eventEditError'));
        break;
    }
  }

  //#endregion

  // #region Create Event
  createEvent() {
    if ((this.isCreation && !this.daterangepicker?.dateControl?.value) || this.form.invalid || this.loading) {
      return;
    }

    this.loading = true;
    return this.api.createEvent(this.formValues).pipe(
      tap((event) => this.successCreateEvent(event)),
      catchError((err: HttpError) => {
        this.errorCreateEvent(err);
        return throwError(err);
      })
    );
  }

  successCreateEvent(event: CalendarEvent) {
    this.loading = false;
    this.setProjectId(event?.project?.id);
    this.CalendarEventEmitter.emit({ action: EventAction.ACTION_ADD, data: { event } });
    this.setCreatorContact(this.creatorContact);
    this.s.success(this.t.instant('CalendarEvents.eventCreatedSuccess'));
  }

  errorCreateEvent(err: HttpError) {
    this.loading = false;
    switch (err.messageCode) {
      case IntilioCodes.WRONG_DATES:
        this.s.error(this.t.instant('CalendarEvents.eventDateTimeError'));
        break;
      default:
        this.s.error(this.t.instant('CalendarEvents.eventCreatedError'));
        break;
    }
  }

  //#endregion

  closeEventSidenav() {
    this.sideService.close();
    this.hardResetView();
  }

  resetEventSidenav() {
    this.form.setValue(this.initialFormValues);
    Object.keys(EVENT_SIDENAV_FORM).forEach((key: string) => {
      this.form.get(key)?.markAsUntouched();
      this.form.get(key)?.updateValueAndValidity({ emitEvent: false });
    });
  }

  hardResetView() {
    this.calendarEvent = null;
    this.resetEventSidenav();
    this.resetDefaultValuesWithinSidenav();
  }

  resetDefaultValuesWithinSidenav() {
    this.task = null;
    this.projectID = null;
    this.participantsList = [];
    this.assignedParticipants = [];

    this.eventProtocolCtrl.reset();
    this.subArray?.unsubscribe();
    this.subFullDay?.unsubscribe();
  }

  discardSaving() {
    const nothingChanged =
      (this.isCreation || this.isEdit) &&
      this.compareFormValues(this.savedFormValues, this.currentFormValues);

    if (this.isPreview || nothingChanged) {
      this.closeEventSidenav();
      return;
    }

    const ref = this.dialog.open(EventDiscardDialogComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
      data: {
        viewState: this.viewState,
        confirm: this.closeEventSidenav.bind(this)
      }
    });
    return ref;
  }
}
