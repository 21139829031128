import { tap } from 'rxjs/operators';
import { BaseHttpService } from '@core/http/base-http.service';
import { Injectable, EventEmitter } from '@angular/core';
import { Config } from '@shared/configs/config';
import { BehaviorSubject, Observable } from 'rxjs';
import { Company } from '@shared/models/company.model';
import { UserPerson } from '@shared/interfaces/user.interface';
import { JWT } from '@shared/interfaces/jwt.interface';
import { ChangePassword } from '@shared/interfaces/change-password.interface';
import { InviteEmployee } from '@modules/settings/pages/team-settings/interfaces/invite-employee.interface';
import { Employee } from '@shared/models/employee.model';
import { UserMailMethod } from '@shared/models/base-user-person.model';
import { ChangeSystemSettingsV2 } from '@shared/interfaces/change-system-settings';
import { ChangeSystemResponse } from '@shared/interfaces/change-system-response.interface';

@Injectable()
export class SettingsService extends BaseHttpService {
  emitter: EventEmitter<SettingsEvent> = new EventEmitter();

  isFormDirtySubject = new BehaviorSubject<boolean>(false);
  isFormDirty$ = this.isFormDirtySubject.asObservable();

  private teamSearchTermSubject = new BehaviorSubject<string>('');
  searchTerm$ = this.teamSearchTermSubject.asObservable();

  private _progressbar: number = 0;

  set progressbar(value: number) {
    this._progressbar = value;
  }

  get progressbar() {
    return this._progressbar;
  }

  changeFormDirty(data: boolean) {
    this.isFormDirtySubject.next(data);
  }

  changeTeamSearchTerm(term: string) {
    this.teamSearchTermSubject.next(term);
  }

  // POST [api_url]/profiler/mail-footer
  // {
  // "useSystemMailFooter": false,
  // "mailFooter": ""

  updateMailingFooter({
    useSystemMailFooter,
    mailFooter
  }: {
    useSystemMailFooter: boolean;
    mailFooter: string;
  }) {
    const url = `${Config.API}/profile/mail-footer`;
    return this.post(url, { useSystemMailFooter, mailFooter });
  }

  getAccessGoogleAccount() {
    const url = `${Config.API}/profile/google`;
    return this.get(url);
  }

  grantAccessGoogleAccount(code: string) {
    const url = `${Config.API}/profile/google?code=${code}`;
    return this.post(url, {});
  }

  setDefaultMethod(mailMethod: UserMailMethod) {
    const url = `${Config.API}/profile/mail-method`;
    return this.post(url, { mailMethod });
  }

  accessGoogleAccount() {
    const url = `${Config.API}/profile/google`;
    return this.get(url);
  }

  generateProjectCreatorToken() {
    const url = `${Config.API}/company/${this.store.Company.id}/generate-token`;
    return this.post(url, {}) as Observable<Company>;
  }

  getCompanyData(): Observable<Company> {
    const url = `${Config.API}/company/${this.store.Company.id}`;
    return this.get(url) as Observable<Company>;
  }

  createCompany() {
    const url = `${Config.API}/company/create`;
    return this.post(url, {}) as Observable<Company>;
  }

  putCompany(company: Company) {
    const url = `${Config.API}/v2/company/${this.store.Company.id}`;
    return this.put(url, company, false, true) as Observable<Company>;
  }

  setBasicData(data: BasicData) {
    const url = `${Config.API}/profile/basic-data`;
    return this.post(url, data, false, true) as Observable<Company>;
  }

  getUserData(): Observable<UserPerson> {
    const url = `${Config.API}/profile`;
    return this.get(url).pipe(
      tap(() => this.emitter.emit({ type: SettingEventType.PROFILE_UPDATED }))
    ) as Observable<UserPerson>;
  }

  putUserData(user: UserPerson): Observable<JWT> {
    const url = `${Config.API}/v2/user`;
    return this.put(url, user, false, true) as Observable<JWT>;
  }

  removeUser() {
    const url = `${Config.API}/profile`;
    return this.delete(url);
  }

  putUserSystem(system: ChangeSystemSettingsV2): Observable<ChangeSystemResponse> {
    const url = `${Config.API}/v2/user/system`;
    return this.put(url, system, false, true) as Observable<ChangeSystemResponse>;
  }

  changePassword(passwords: ChangePassword) {
    const url = `${Config.API}/profile/change-password`;
    return this.post(url, passwords, false, true) as Observable<UserPerson>;
  }

  getListEmployees(term?: string): Observable<Employee[]> {
    const url = `${Config.API}/employee`;

    let params: any = {};
    if (term && term.trim() !== '') {
      params.search = term.trim();
    }

    return this.get(url, false, params) as Observable<Employee[]>;
  }

  blockEmployee(employee: Employee) {
    const url = `${Config.API}/employee/${employee.id}/block`;
    return this.post(url, { blocked: true });
  }

  unblockEmployee(employee: Employee) {
    const url = `${Config.API}/employee/${employee.id}/block`;
    return this.post(url, { blocked: false });
  }

  inviteEmployee(inviteEmployee: InviteEmployee) {
    const url = `${Config.API}/employee/invite`;
    return this.post(url, inviteEmployee);
  }

  editEmployee(employee: Employee) {
    const url = `${Config.API}/employee/${employee.id}/role`;
    return this.post(url, { role: employee.role, hourlyRate: employee.hourlyRate });
  }

  sendPasswordLink(employee: Employee) {
    const url = `${Config.API}/employee/${employee.id}/reset-password`;
    return this.post(url, employee);
  }

  sendInviteAgain(employee: Employee) {
    return this.inviteEmployee({ role: employee.role, email: employee.userPerson.email });
  }

  removeEmployee(employee: Employee) {
    const url = `${Config.API}/employee/${employee.id}`;
    return this.delete(url);
  }

  showDoneTasks(value: number) {
    const url = `${Config.API}/profile/show-done-tasks?show=${value}`;
    return this.post(url, {});
  }
}

export class SettingsEvent {
  type: SettingEventType;
}

export enum SettingEventType {
  RELOAD_EMPLOYEES_LIST,
  COMPANY_CREATED,
  PROFILE_UPDATED
}

export interface BasicData {
  firstName: string;
  lastName: string;
  companyName?: string;
}
