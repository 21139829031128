import { Component, ElementRef, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'input-v2',
  templateUrl: './input-v2.component.html',
  styleUrls: ['./input-v2.component.scss']
})
export class InputV2Component implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() placeholder: string = '';
  @Input() id: string = '';
  @Input() type: string = 'text';
  @Input() errMessages: { [key: string]: string } = {};
  @Input() prependSrc = '';
  @ViewChild('inputv2') inputv2: ElementRef<HTMLInputElement>;

  @Input() set readonly(value: boolean) {
    this._readonly = value;
  }

  get readonly(): boolean {
    return this._readonly;
  }

  _readonly = false;

  value: string = '';
  isDisabled: boolean = false;
  isTouched: boolean = false;
  isInvalid: boolean = false;
  errors: { [key: string]: any } = {};

  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};
  private control: AbstractControl | null = null;

  destroy$ = new Subject();

  get pattern() {
    if (this.type === 'number') {
      return 'd*';
    }
    return '';
  }

  get errorMessage(): string {
    if (!this.isInvalid || !this.isTouched) return '';

    for (const errorKey in this.errors) {
      if (this.errMessages[errorKey]) {
        return this.getValidatorMessage(errorKey);
      }
    }

    return '';
  }

  constructor(
    @Optional()
    @Self()
    public ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (this.ngControl) {
      this.control = this.ngControl.control;

      this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.validate();
      });
      this.control.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.validate();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onInputChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.value = value;
    this.onChange(value);
    this.validate();
  }

  onBlur(): void {
    if (!this.isTouched) {
      this.isTouched = true;
      this.onTouched();
    }
    this.validate();
  }

  private validate(): void {
    if (this.control) {
      this.errors = this.control.errors || {};
      this.isInvalid = this.control.invalid;
      this.isTouched = this.control.touched;
    }
  }

  getValidatorMessage(name: string) {
    switch (name) {
      case GbxsoftErrorTypes.pattern:
        const pattern = this.control.errors.pattern.requiredPattern;
        return this.errMessages && this.errMessages[pattern]
          ? this.errMessages[pattern]
          : 'No pattern validator';
      default:
        return this.errMessages && this.errMessages[name]
          ? this.errMessages[name]
          : this.getDefaultMessages(name);
    }
  }

  getDefaultMessages(errorType: string) {
    const err = this.control.errors[errorType];

    switch (errorType) {
      case GbxsoftErrorTypes.red:
        return '&nbsp;';
      case GbxsoftErrorTypes.required:
        return 'This field is required.';
      case GbxsoftErrorTypes.min:
        return `Please enter a value greater than or equal to ${err.min}.`;
      case GbxsoftErrorTypes.max:
        return `Please enter a value less than or equal to ${err.max}.`;
      case GbxsoftErrorTypes.email:
        return 'Please enter a valid email address.';
      case GbxsoftErrorTypes.minLength:
        return `Please enter at least ${err.requiredLength} characters.`;
      case GbxsoftErrorTypes.maxLength:
        return `Please enter no more than ${err.requiredLength} characters.`;
      default:
        return 'No validator message';
    }
  }
}
