import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigateService } from '@shared/services/navigate.service';
import {
  BasePanelChangeEvent,
  BasePanelChangeEventType,
  BasePanelService,
} from '@shared/modules/base-panel/services/base-panel.service';
import { Subscription } from 'rxjs';
import { StorageService } from '@core/services/storage.service';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { TabLink } from '@shared/interfaces/menu/tab-link.interface';
import { HeaderConfig } from '@shared/configs/header.config';
import { CheckPermission } from '@core/permissions/check-permission';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { HeaderService } from '../header/services/header.service';
import { Employee } from '@shared/models/employee.model';
import { ContactController } from '@modules/contacts/shared/controllers/contact.controller';
import { ProjectController } from '@modules/projects/shared/controllers/project.controller';
import { EventSidenavController } from '@shared/modules/event-sidenav/controllers/event-sidenav.controller';
import { MailingController } from '@shared/modules/mailings/shared/controllers/mailing.controller';
import { ChangesSidenavController } from '@shared/modules/task-sidenav/controllers/changes-sidenav.controller';
import { TaskSidenavController } from '@shared/modules/task-sidenav/controllers/task-sidenav.controller';
import { trigger, transition, style, animate, state } from '@angular/animations';

@Component({
  selector: 'intilio-header-v2',
  templateUrl: './header-v2.component.html',
  styleUrls: ['./header-v2.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('200ms', style({ opacity: 0 }))]),
    ]),
    trigger('openClose', [
      state('true', style({ width: '230px' })),
      state('false', style({ width: '68px' })),
      transition('false <=> true', animate(200)),
    ]),
  ],
})
export class HeaderV2Component implements OnInit, OnDestroy {
  @Input() isExpanded = false;

  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  PermissionsGroups = PermissionsGroups;

  sub: Subscription;
  tabLinks: TabLink[] = [];

  @ViewChild('header') header: ElementRef;

  employee: Employee;

  constructor(
    public n: NavigateService,
    private basePanelService: BasePanelService,
    public store: StorageService,
    public headerService: HeaderService,
  ) {
    this.configureNavigation();

    this.employee = this.store.Employee;
  }

  ngOnInit(): void {
    this.listenChange();
  }

  listenChange() {
    this.basePanelService.panelChangeEmitter.subscribe((event: BasePanelChangeEvent) => {
      switch (event.type) {
        case BasePanelChangeEventType.HIDE_TOP_MENU:
          this.header.nativeElement.classList.add('hide');
          break;
        case BasePanelChangeEventType.SHOW_TOP_MENU:
          this.header.nativeElement.classList.remove('hide');
          break;
      }
    });
  }

  configureNavigation() {
    const ctrl = new CheckPermission(null);
    Object.values(HeaderConfig).map((tabLink: TabLink) => {
      if (!tabLink.permissionAction || !isNotNullOrUndefined(tabLink.permissionGroup)) {
        this.tabLinks = [];
        return console.error('ERROR Error: There is no permissions declared for header navigation!');
      }

      if (
        (tabLink.permissionGroup === PermissionsGroups.CONTACTS ||
          tabLink.permissionGroup === PermissionsGroups.PROJECTS) &&
        tabLink.permissionAction === 'LIST'
      ) {
        const employee = this.store.Employee;
        ctrl.setPermissionDecorator({
          group: tabLink.permissionGroup,
          action: tabLink.permissionAction,
          objectCreatorId: employee?.hasResponsibleProjects ? employee.id : null,
        });
      } else {
        ctrl.setPermissionDecorator({ group: tabLink.permissionGroup, action: tabLink.permissionAction });
      }

      if (ctrl.check()) {
        this.tabLinks.push(tabLink);
      }
    });
  }

  ngOnDestroy() {
    this.sub ? this.sub.unsubscribe() : null;
  }

  createTask() {
    const ctrl = new TaskSidenavController();
    ctrl.addTask(null);
    this.headerService.closeMenu();
  }

  createChange() {
    const ctrl = new ChangesSidenavController();
    ctrl.addChange(null);
    this.headerService.closeMenu();
  }

  createEvent() {
    const ctrl = new EventSidenavController();
    ctrl.createEvent();
    this.headerService.closeMenu();
  }

  createContact() {
    const ctrl = new ContactController();
    ctrl.add().then(() => this.headerService.closeMenu());
  }

  createProjectDraft() {
    const ctrl = new ProjectController();
    ctrl.add();
    this.headerService.closeMenu();
  }

  createEmail() {
    const ctrl = new MailingController();
    ctrl.openMailingSelection('');
    this.headerService.closeMenu();
  }

  isTimelineRoute(tabLink: TabLink) {
    const isProjects = window.location.href.indexOf('projects') !== -1;
    if (tabLink.link === 'projects-list' && isProjects) {
      return true;
    }
    return false;
  }
}
