<section class="grey-v2">
  <ng-content></ng-content>

  <div class="settings-container">
    <div class="tabs-v2 mr-3">
      <div
        #tabs
        [ngClass]="{ 'pt-0': !tabsTemplate || hideTopTabIconOnMobile, 'pt-md-3': hideTopTabIconOnMobile }"
      >
        <app-content-tabs-v2
          [selectTabsOnMobile]="selectTabsOnMobile"
          [tabsTemplate]="tabsTemplate"
          [tabsDescription]="tabsDescription"
          [tabsDescriptionTemplate]="tabsDescriptionTemplate"
          [tabLinks]="tabLinks"
          [hideTopTabIconOnMobile]="hideTopTabIconOnMobile"
        ></app-content-tabs-v2>
      </div>
    </div>
    <div class="content scroll-content">
      <div class="content-with-tabs content-with-tabs-content">
        <router-outlet *ngIf="!contentTemplate"></router-outlet>
        <ng-container *ngIf="contentTemplate" [ngTemplateOutlet]="contentTemplate"></ng-container>
      </div>
    </div>
  </div>
</section>
