<div [ngClass]="{ 'has-error': isInvalid && isTouched && errorMessage }">
  <input
    #inputv2
    class="input-v2"
    [ngClass]="{ 'error-border': isInvalid && isTouched && errorMessage }"
    [ngStyle]="
      prependSrc
        ? {
            background: 'url(' + prependSrc + ') no-repeat scroll 16px 11px',
            paddingLeft: '42px'
          }
        : {}
    "
    [value]="value"
    (input)="onInputChange($event)"
    (blur)="onBlur()"
    [attr.readOnly]="readonly ? 'readonly' : null"
    [pattern]="pattern"
    [disabled]="isDisabled"
    [type]="type"
    [placeholder]="placeholder"
  />

  <span
    *ngIf="isInvalid && isTouched"
    class="form-control-error-msg d-flex mt-2"
    [innerHTML]="errorMessage"
  ></span>
</div>
