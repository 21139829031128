import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Component } from '@angular/core';
import { EventSidenavService } from '@shared/modules/event-sidenav/services/event-sidenav.service';
import { EventSidenavController } from '@shared/modules/event-sidenav/controllers/event-sidenav.controller';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent {
  PermissionsGroups = PermissionsGroups;
  eventCtrl: EventSidenavController = new EventSidenavController();

  constructor(
    public service: EventSidenavService,
    public sanitizer: DomSanitizer
  ) {}

  editOpen() {
    this.eventCtrl.openEdit(this.service.calendarEvent);
  }
}
