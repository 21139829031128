import { AfterViewInit, Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Config } from '@shared/configs/config';
import { Project } from '@modules/projects/shared/models/project.model';
import { ContactAccessProject } from '@shared/models/contact-access-project.model';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { NgSelectExtensionDirective } from '@shared/directives/ng-select-extension.directive';
import { WindowHelper } from '@shared/helpers/window.helper';
import { ContactType } from '@modules/contacts/shared/enums/contact-type.enum';
import { ContactController } from '@modules/contacts/shared/controllers/contact.controller';
import { CheckPermission } from '@core/permissions/check-permission';
import { PermissionsGroups } from '@core/permissions/permissions.group';

@Component({
  selector: 'project-main-contact-modal',
  templateUrl: './project-main-contact-modal.component.html',
  styleUrls: ['./project-main-contact-modal.component.scss']
})
export class ProjectMainContactModalComponent implements OnInit, AfterViewInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  Config = Config;
  WindowHelper = WindowHelper;

  loading: boolean = false;
  form: FormGroup = new FormGroup({
    mainContact: new FormControl(null),
    responsible: new FormControl(null)
  });
  eventEmitter: EventEmitter<Project> = new EventEmitter<Project>();
  project: Project;
  contactUrl = Config.API + '/contact/search';
  responsibleUrl = Config.API + '/contact/search';

  @ViewChild('contactSelect') contactSelect: NgSelectExtensionDirective;
  @ViewChild('responsibleSelect') responsibleSelect: NgSelectExtensionDirective;

  constructor(
    public dialogRef: MatDialogRef<ProjectMainContactModalComponent>,
    private service: ProjectAPIService,
    private t: TranslateService,
    private s: SnackBarService
  ) {
  }

  ngOnInit() {
    if (this.project?.MainContact) {
      this.contactUrl += '?requiredId=' + this.project.MainContact.id;
    }
    this.project?.basicDataBox?.contacts.map((access: ContactAccessProject) => {
      if (access.isResponsible) {
        this.responsibleUrl += '?requiredId=' + access.contact.id;
      }
    });
  }

  ngAfterViewInit() {
    this.setMainContactAndResponsiblePerson();
  }

  setMainContactAndResponsiblePerson() {
    this.project?.basicDataBox?.contacts.map((access: ContactAccessProject) => {
      if (access.isResponsible) {
        this.form.get('responsible').setValue(access.contact.id);
      }
    });
    if (this.project?.MainContact) {
      this.form.get('mainContact').setValue(this.project.MainContact.id);
    }
  }

  addNewContactAndAssign(value: string) {
    const ctrl = new ContactController(null, [this.project?.basicDataBox?.responsibleEmployee?.id]);
    ctrl.add({assignToProject: this.project.id, firstName: value}, ContactType.PERSONAL);
    this.dialogRef.close();
  }

  addNewContact(value: string) {
    const ctrl = new ContactController(null, [this.project?.basicDataBox?.responsibleEmployee?.id]);
    ctrl.add({firstName: value, backToProject: this.project.id}, ContactType.PERSONAL);
    this.dialogRef.close();
  }

  getCheckPermissionController() {
    return new CheckPermission({
      group: PermissionsGroups.CONTACTS,
      action: 'ADD'
    });
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.loading || !this.form.valid) {
      return;
    }
    this.loading = true;
    const assignMainContactObs = this.service.assignMainContactToProject(this.project.id, this.form.get('mainContact').value);
    const setResponsibleObs = this.service.addContactToProject(this.form.get('responsible').value, this.project.id, this.form.get('responsible').value ? true : false);
    const removeMainContact = this.project.MainContact ? this.service.removeContactFromProject(this.project.basicDataBox.mainContact.id) : null;

    const obsArr = [];
    this.form.get('mainContact').value ? obsArr.push(assignMainContactObs) : obsArr.push(removeMainContact);
    this.form.get('responsible').value ? obsArr.push(setResponsibleObs) : null;

    combineLatest(obsArr).subscribe({
      next: (res: Project[]) => {
        this.s.success(this.t.instant('Projects.MainContact.successSaveData'));
        this.dialogRef.close();
        if (this.form.get('mainContact').value) {
          this.eventEmitter.emit(res[0]);
        } else {
          this.project.basicDataBox.mainContact = null;
          this.eventEmitter.emit(this.project);
        }
      },
      error: () => {
        this.s.error(this.t.instant('Projects.MainContact.errorSaveData'));
      }
    }).add(() => {
      this.loading = false;
    });
  }
}
