import { Currency } from '@shared/models/currency.interface';
import { environment } from 'src/environments/environment';
import { GOOGLE_DOCS_EXTENSIONS } from './google-docs-extensions';
import { VideoExtensions } from './video-extensions';
import { CurrencyFormat } from '@shared/enums/curreny-format.enum';

export class Config {
  static readonly SERVER = environment.SERVER;
  static readonly DATE_SERVER = 'YYYY-MM-DD HH:mm:ss';
  static readonly DATE_SERVER_YYMMDD = 'YYYY-MM-DD';
  static readonly DATE_FORMAT_DOTS = 'DD.MM.YYYY';
  static readonly DATETIME_FORMAT_DOTS = 'DD.MM.YYYY HH:mm';
  static readonly DATETIME_MONTH = 'D MMMM YYYY';
  static readonly TIME = 'HH:mm';
  static readonly PREVIEW_DAY_MONTH = 'ddd, D MMM';

  static validationMessages: { [key: string]: string } = {};

  static readonly DEBUG = !!environment.DEBUG;
  static readonly API = environment.API;
  static readonly PUBLIC_PHOTO = environment.PUBLIC_PHOTO;
  static readonly PRIVATE_ATTACHMENT = environment.PRIVATE_ATTACHMENTS;
  static readonly GTAG = environment.GTAG;
  static readonly FULL_CALENDAR_LICENSE_KEY = environment.FULL_CALENDAR_LICENSE_KEY;
  static readonly VIDEO_EXTENSIONS = VideoExtensions;

  static readonly GOOGLE_PLAY_URL =
    'https://play.google.com/store/apps/details?id=pl.gbxsoft.intilio&hl=pl&gl=US';
  static readonly APP_STORE_URL = 'https://apps.apple.com/us/app/intilio/id1543966583';

  static FILES_WITHOUT_IMAGE_ACCEPT =
    'audio/*, video/*, text/*, media_type, application/*, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  static readonly B_TO_MB = 1048576;

  static readonly FILE_SIGNATURE_VALID_TIME = 60 * 60 * 2; // 2h

  static readonly DATEPICKER_ANGULAR_MATERIAL_FORMATS = {
    parse: {
      dateInput: Config.DATE_FORMAT_DOTS,
    },
    display: {
      dateInput: Config.DATE_FORMAT_DOTS,
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };

  static readonly SYSTEM_NAME = 'intilio';
  static readonly INSTANCE_NAME = environment.INSTANCE_NAME;
  static readonly CONTACT_EMAIL = environment.CONTACT_EMAIL;

  static readonly MAX_NUMBER = 90000000000000000;
  static readonly MAIN_VIEW: string = 'projects-list';

  static currency: Currency = null;

  static GOOGLE_DOCS_EXTENSIONS = GOOGLE_DOCS_EXTENSIONS;

  static DEFAULT_MODAL_WIDTH: string = '482px';

  static BOTTOM_TOASTER_CONFIG = { positionClass: 'toast-bottom-left' };
  static CENTER_TOASTER_CONFIG = { positionClass: 'toast-top-center-margin' };

  static DEFAULT_NUMBER_FORMAT = CurrencyFormat.NUMBER_FORMAT_COMMA;
  // static GOOGLE_DOCS_MAX_FILE_SIZE = 25000000;

  static GOOGLE_DOCS_LINK = (file_url: string) =>
    `https://view.officeapps.live.com/op/embed.aspx?src=${file_url}&embedded=true`
}
