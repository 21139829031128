import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { GbxsoftInputConfig } from '@form/src/lib/gbxsoft-input/interfaces/gbxsoft-input.interface';
import { GbxsoftInputTypes } from '@form/src/lib/gbxsoft-input/gbxsoft-input.types';
import { FormGroup } from '@angular/forms';
import {
  CountryPickerV2Component,
  CountryPickerEvent,
  CountryPickerEventType,
} from './components/country-picker-v2/country-picker-v2.component';
import { BaseComponent } from '../base.component';
import { Config } from '@shared/configs/config';
import { InputV2Component } from '../input-v2/input-v2.component';

@Component({
  selector: 'phone-input-v2',
  templateUrl: './phone-input-v2.component.html',
  styles: [],
})
export class PhoneInputV2Component extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('input') input: InputV2Component;

  private _form: FormGroup;

  @Input()
  set form(form: FormGroup) {
    this._form = form;
    if (this.countryPickerV2Component) {
      this.countryPickerV2Component.form = form;
      this.countryPickerV2Component.checkFormPhoneFlagValue();
      this.countryPickerV2Component.ref.detectChanges();
    }
  }

  get form() {
    return this._form;
  }

  @Input() phoneFlagName: string;
  @Input() phoneName: string;
  @Input() config: GbxsoftInputConfig;
  @Input() disabled: boolean;
  @Input() inputPlaceholder: string;
  @ViewChild('countryPickerV2Component') countryPickerV2Component: CountryPickerV2Component;

  changesEmitter: EventEmitter<CountryPickerEvent> = new EventEmitter();

  constructor(public changes: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.config ? (this.config.type = GbxsoftInputTypes.NUMBER) : null;
    this.initListener();
  }

  initListener() {
    this.sub = this.changesEmitter.subscribe((e: CountryPickerEvent) => {
      if (e.eventType === CountryPickerEventType.CHOSE) {
        this.input ? this.input.inputv2.nativeElement.focus() : null;
      }
    });
  }

  ngAfterViewInit() {
    const inp: HTMLInputElement = this.input.inputv2.nativeElement;
    inp.onpaste = this.cleanPhoneNumber.bind(this);
  }

  recalculateFlag() {
    this.countryPickerV2Component.checkFormPhoneFlagValue();
    this.countryPickerV2Component.ref.detectChanges();
  }

  cleanPhoneNumber(e) {
    e.preventDefault();
    let pastedText = '';

    if (e.clipboardData && e.clipboardData.getData) {
      // Standards Compliant FIRST!
      pastedText = e.clipboardData.getData('text/plain');
    } else if (window['clipboardData'].getData) {
      // IE
      pastedText = window['clipboardData'].getData('Text');
    }

    this.form.get(this.phoneName).setValue(pastedText.replace(/\D/g, ''));
  }

  errorMessages(name: string) {
    return Config.validationMessages;
  }
}
