<div class="content-with-tabs-v2 content-with-tabs-menu-v2">
  <div class="content-with-tabs-menu-tabs-v2 vertical-tabs">
    <nav mat-tab-nav-bar>
      <a
        draggable="false"
        mat-tab-link
        class="tab-link-v2"
        (click)="scrollToContent(tabLink.link)"
        [routerLinkActive]="['active']"
        [routerLink]="[tabLink.link]"
        [queryParams]="tabLink.queryParams"
        [permission]="
          tabLink?.permissionGroup && tabLink?.permissionAction
            ? {
                group: tabLink?.permissionGroup,
                action: tabLink?.permissionAction,
                objectCreatorId: tabLink?.objectCreatorId || null
              }
            : {}
        "
        *ngFor="let tabLink of tabLinks"
      >
        <span>{{ tabLink.name }}</span>
      </a>
    </nav>
  </div>
</div>
