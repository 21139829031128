import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { ActivatedRoute } from '@angular/router';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ContactController } from '@modules/contacts/shared/controllers/contact.controller';
import { Subscription } from 'rxjs';
import { NavigateService } from '@shared/services/navigate.service';
import { CommentaryType } from '@shared/modules/commentary/enums/commentary-type.enum';
import { Employee } from '@shared/models/employee.model';
import { CommentaryManageComponent } from '@shared/modules/commentary/components/commentary-manage/commentary-manage.component';
import { RemoveModalController } from '@shared/controllers/remove-modal.controller';

@Component({
  selector: 'contact-preview-details',
  templateUrl: './contact-preview-details.component.html',
  styleUrls: ['./contact-preview-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPreviewDetailsComponent implements OnInit, OnDestroy {
  CommentaryType = CommentaryType;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  PermissionsGroups = PermissionsGroups;
  contact: Contact;
  contactController: ContactController;
  sub: Subscription;

  @ViewChild('comments') comments: CommentaryManageComponent;

  constructor(private route: ActivatedRoute, public changes: ChangeDetectorRef, private n: NavigateService) {}

  ngOnInit(): void {
    this.sub = this.route.parent.data.subscribe((data: { contact: Contact }) => {
      this.contact = new Contact(data.contact);
      this.contactController = new ContactController(this.contact);
      this.changes.detectChanges();
    });
  }

  updateCommentaries($event) {
    this.contact.comments = this.comments.commentaries;
    this.route.parent.snapshot.data.contact.comments = this.comments.commentaries;
    this.changes.detectChanges();
  }

  remove() {
    const ctrl = new RemoveModalController();
    ctrl
      .remove(() => {
        const _ctrl = new ContactController();
        _ctrl.remove(this.contact).subscribe({
          next: () => {
            this.n.go('contacts-list');
          },
        });
      })
      .subscribe(() => {});
  }

  onRemoveSubcontactContactPerson(removedContact: Contact) {
    this.contact.childContacts = this.contact.childContacts.filter((childContact: Contact) => {
      return childContact.id !== removedContact.id;
    });
    this.contact.contactPerson = null;
    this.route.parent.snapshot.data.contact = this.contact;
    this.changes.detectChanges();
  }

  onRemoveContactPersonFromMain(contactPerson: Contact) {
    this.contact.childContacts.map((childContact: Contact) => {
      if (childContact.id === contactPerson.id) {
        this.contact.contactPerson = null;
        childContact.isContactPerson = false;
      }
    });
    this.route.parent.snapshot.data.contact = this.contact;
    this.changes.detectChanges();
  }

  onChangeContactPerson(contactPerson: Contact) {
    this.contact.childContacts.map((childContact: Contact) => {
      if (childContact.id === contactPerson.id) {
        this.contact.contactPerson = new Contact(contactPerson);
        childContact.isContactPerson = true;
      } else {
        childContact.isContactPerson = false;
      }
    });
    this.route.parent.snapshot.data.contact = this.contact;
    this.changes.detectChanges();
  }

  onAddProjectToContact(contact: Contact) {
    this.contact = new Contact(contact);
    this.route.parent.snapshot.data.contact = this.contact;
    this.changes.detectChanges();
  }

  onAddAccount(employee: Employee) {
    this.contact.employee = employee;
    this.route.parent.snapshot.data.contact = this.contact;
  }

  onUpdateContact(contact: Contact) {
    this.contact = new Contact(contact);
    this.route.parent.snapshot.data.contact = this.contact;
    this.changes.detectChanges();
  }

  getContactPersonWithParent() {
    let contact = new Contact(this.contact.contactPerson);
    contact.parentContacts.push(this.contact);
    return contact;
  }

  ngOnDestroy() {
    this.sub ? this.sub.unsubscribe() : null;
  }
}
