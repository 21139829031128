import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NgSelectComponent, NgOption } from '@ng-select/ng-select';
import { BaseHttpService } from '@core/http/base-http.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';

@Directive({
  selector: '[ngSelectExtension]',
  exportAs: 'ngSelectExtensionDir'
})
export class NgSelectExtensionDirective implements OnInit, OnDestroy, AfterViewInit {
  @Input() url: string; //URL to getting values for dropdown | Important! If you provide in ng-select items as array, then this directive wouldn't use on init getting Items through provided url
  @Input() searchUrl?: string; //You can add diffrent url for searching than to getting all items
  @Input() searchGetParam?: string; //You can set name for get param for searching - default: query
  @Input() responseTransform: Function; //You can transform response from server by this input function
  @Input() searchFunction?: Function; //You can set your own search function
  @Input() addNewItemFunction?: Function; //You can set addNewItemFunction to handle newly item
  @Input() addExistItemFunction?: Function; //You can set addExistItemFunction to handle old item
  @Input() initialValue?: any[]; // Initial selected values
  @Input() addItems: EventEmitter<any[]>; //If you want to add dynamically new item to items, use this event emitter
  @Input() translateLabelPrefix: string; //If you want to add dynamically new item to items, use this event emitter
  @Input() ignoreLocalSearch?: boolean = false;
  @Input() loadingSpinnerDisabled?: boolean = false;
  @Input() sortByField?: string = 'id';
  @Input() enableSort?: boolean = true;
  @Input() originalList?: boolean = false;
  @Input() changePromise?: (item?: NgOption) => Promise<boolean>;

  @Output() response: EventEmitter<any> = new EventEmitter(); // you can listen event on getting response

  private static ngSelectTranslateOption: ComponentRef<NgSelectTranslateOptionComponent>;
  private static ngSelectTranslateValue: ComponentRef<NgSelectTranslateValueComponent>;

  searchTimeout;
  subs: Subscription = new Subscription();

  private _items: any[] = [];
  private _localSearchItems: any[] = [];

  get items() {
    return this._items;
  }

  set items(items: any[]) {
    if (this.originalList) {
      this._items = items;
      return;
    }
    if (!this.items.length && this.initialValue) {
      const selected = [...this.select.selectedItems];
      this._items = this.setInitialValue(items.concat(selected));

      this._items.forEach((it, i) => {
        if (it.htmlId !== undefined) {
          this._items.splice(i, 1);
        }
      });
      this._localSearchItems = this._items;
    } else {
      this._items = this.removeDuplicates(this.select.selectedItems.map((i) => i?.value).concat(items));
      this._localSearchItems = this.removeDuplicates(
        this.select.selectedItems.map((i) => i?.value).concat(items.concat(this._localSearchItems))
      );
    }
  }

  get RemoveDuplicatesKey() {
    return this.select.bindValue ? this.select.bindValue : 'id';
  }

  get CheckExistanceByParam() {
    return this.select.bindLabel ? this.select.bindLabel : 'name';
  }

  constructor(
    public select: NgSelectComponent,
    private http: BaseHttpService,
    private t: TranslateService,
    private s: SnackBarService,
    private resolver: ComponentFactoryResolver,
    private _vcr: ViewContainerRef
  ) {}

  ngOnInit() {
    this.select.toggleItem = this._customSelectToggle.bind(this);

    this.setListenSearch();
    this.setListenChange();
    this.setListenAddItems();
    !this.select.items?.length ? ((this.select.items = []), this.getItems()) : null;
    this.handleEnterKey();
  }

  ngAfterViewInit() {
    this.setTranslateTemplate();
  }

  private async _customSelectToggle(item) {
    if (!item || item.disabled || this.select.disabled) {
      return;
    }

    if (this.changePromise && !item.selected) {
      const canChange = await this.changePromise(item);
      canChange ? this._updateSelect(item) : null;
    } else {
      this._updateSelect(item);
    }
  }

  private _updateSelect(item) {
    if (this.select.multiple && item.selected) {
      this.select.unselect(item);
    } else {
      this.select.select(item);
    }

    if ((this.select as any)._editableSearchTerm) {
      (this.select as any)._setSearchTermFromItems();
    }
    (this.select as any)._onSelectionChanged();
  }

  handleEnterKey() {
    if (!this.select.addTag) {
      return;
    }
    this.select.changeEvent.subscribe(() => {
      this.select.searchInput.nativeElement.value = '';
      this.select.detectChanges();
    });
    this.select.handleKeyDown = ($event) => {
      if ($event.keyCode === 13) {
        // THIS option is only available after load ends
        if (this.select.loading) {
          return;
        }
        const search = this.select.searchTerm;
        const list = [...[].concat(this.select.selectedItems, this._items)];
        const filterItems = list.filter(
          (i) =>
            i[this.select.bindLabel]?.toLowerCase() === search?.toLowerCase() ||
            i.label?.toLowerCase() === search?.toLowerCase()
        );
        if (!filterItems.length && search.length) {
          this.select.selectTag();
        }
      }
    };
  }

  setSelectItems(res: any) {
    this.items = this.responseTransform ? this.responseTransform(res) : res;
    this.sortByFieldName();
    this.response.emit(this.items);
    this.select.ngOnChanges({
      items: {
        previousValue: [],
        currentValue: this.items,
        firstChange: false,
        isFirstChange: () => false
      }
    });

    this.select.detectChanges();
  }

  getItems(params?: any, localSearchResponse: any[] = [], searching?: boolean) {
    if (this.url) {
      !this.loadingSpinnerDisabled ? (this.select.loading = true) : null;
      const url = searching && this.searchUrl ? this.searchUrl : this.url;
      this.select.detectChanges();
      return this.http
        .get(url, false, params)
        .subscribe({
          next: (res) => {
            if (searching && !this.ignoreLocalSearch) {
              if (Array.isArray(res)) {
                res = localSearchResponse.concat(res);
              }
            }
            this.setSelectItems(res);
          },
          error: (e) => this.s.error(this.t.instant('Global.errorGetDataSelect'))
        })
        .add(() => {
          this.select.loading = false;
          this.select.detectChanges();
        });
    }
  }

  setTranslateTemplate() {
    if (this.translateLabelPrefix) {
      const ngSelectTranslateOptionComponentRef = this.getTranslateOptionComponentRef();
      ngSelectTranslateOptionComponentRef.changeDetectorRef.detectChanges();
      ngSelectTranslateOptionComponentRef.instance.translateLabelPrefix = this.translateLabelPrefix;
      ngSelectTranslateOptionComponentRef.instance.bindLabel = this.select.bindLabel;
      const optionTemplate = ngSelectTranslateOptionComponentRef.instance.ngSelectTranslateOption;

      const ngSelectTranslateValueComponentRef = this.getTranslateValueComponentRef();
      ngSelectTranslateValueComponentRef.changeDetectorRef.detectChanges();
      ngSelectTranslateValueComponentRef.instance.translateLabelPrefix = this.translateLabelPrefix;
      ngSelectTranslateValueComponentRef.instance.bindLabel = this.select.bindLabel;
      ngSelectTranslateValueComponentRef.instance.clearable = this.select.clearable;
      const labelTemplate = ngSelectTranslateValueComponentRef.instance.ngSelectTranslateValue;

      this.select.optionTemplate = optionTemplate;
      this.select.labelTemplate = labelTemplate;
    }
  }

  private sortByFieldName() {
    if (!this.enableSort || this.originalList) {
      return;
    }
    this.items.forEach((i, index) => {
      if (!i.id) {
        const id = Math.max(...this.items.map((j) => (!!j.id ? j.id : 0))) + 1;
        this.items[index].id = id;
      }
    });
    this.items.sort((a, b) => a[this.sortByField] - b[this.sortByField]);
  }

  private getTranslateOptionComponentRef() {
    if (!NgSelectExtensionDirective.ngSelectTranslateOption) {
      const factory = this.resolver.resolveComponentFactory(NgSelectTranslateOptionComponent);
      return this._vcr.createComponent(factory);
    }
    return NgSelectExtensionDirective.ngSelectTranslateOption;
  }

  private getTranslateValueComponentRef() {
    if (!NgSelectExtensionDirective.ngSelectTranslateValue) {
      const factory = this.resolver.resolveComponentFactory(NgSelectTranslateValueComponent);
      return this._vcr.createComponent(factory);
    }
    return NgSelectExtensionDirective.ngSelectTranslateValue;
  }

  private setListenSearch() {
    if (this.select.searchable) {
      this.setLocalSearchToSelect();
      const subSearch = this.select.searchEvent.subscribe((e: { term; items }) => {
        if (!(this.url || this.searchUrl)) {
          return;
        }
        clearTimeout(this.searchTimeout);
        this.select.loading = true;
        this.select.detectChanges();
        this.searchTimeout = setTimeout(() => {
          const searchQuery = e?.term ? this.getSearchGetParams(e.term) : '';
          if (this.searchFunction) {
            this.setSelectItems(this.searchFunction(searchQuery));
          } else {
            this.getItems(searchQuery, this.getLocalSearch(e.term), true);
          }
        }, 300);
      });
      this.subs.add(subSearch);
      this.setCloseEvent();
      this.setClearEvent();
    }
  }

  private setLocalSearchToSelect() {
    this.select.searchFn = (term, item) => {
      if (!(this.url || this.searchUrl)) {
        const bindLabel = this.select.bindLabel ? this.select.bindLabel : 'id';
        const val = this.getTranslatedLabel(item[bindLabel]);
        if (val.toUpperCase().indexOf(term?.trim()?.toUpperCase()) > -1) {
          return item;
        }
        return null;
      } else {
        return item;
      }
    };
  }

  private setCloseEvent() {
    const subClose = this.select.closeEvent.subscribe(() => {
      this.getItems();
    });
    this.subs.add(subClose);
  }

  private setClearEvent() {
    const subClear = this.select.clearEvent.subscribe(() => {
      this.getItems();
    });
    this.subs.add(subClear);
  }

  private getLocalSearch(term) {
    return this._localSearchItems.filter((item) => {
      const bindValue = this.select.bindValue ? this.select.bindValue : 'id';
      const val = this.getTranslatedLabel(item[bindValue]);
      if (
        isNotNullOrUndefined(val) &&
        val?.toString().toUpperCase().indexOf(term?.trim()?.toUpperCase()) > -1
      ) {
        return true;
      }
      return false;
    });
  }

  getTranslatedLabel(value: string) {
    if (this.translateLabelPrefix) {
      const translation = this.t.instant(`${this.translateLabelPrefix}${value}`);
      if (translation !== `${this.translateLabelPrefix}${value}`) {
        return translation;
      }
    }
    return value;
  }

  private setListenChange() {
    const sub = this.select.changeEvent.subscribe((data) => {
      if (this.select.addTag) {
        this.isNewItem(data) ? this.addNewItem(data) : this.setExistValue(data);
        this.select.detectChanges();
      } else {
        this.setExistValue(data);
      }
      this.clearSearchInput();
    });
    this.subs.add(sub);
  }

  private clearSearchInput() {
    this.select.searchInput.nativeElement.value = '';
    this.select.searchEvent.emit({ term: this.select.searchInput.nativeElement.value, items: [] });
    this.select.detectChanges();
  }

  private addNewItem(data) {
    this.addNewItemFunction
      ? this.addNewItemFunction(this.select.multiple ? data[data.length - 1] : data, data)
      : null;
  }

  private setExistValue(data) {
    this.addExistItemFunction ? this.addExistItemFunction(data, this._items) : null;
  }

  private isNewItem(item: any | Array<any>): boolean {
    let exists = false;
    const itemExists = this.select.multiple ? !!(item as Array<any>).length : !!item;

    if (this.select.multiple) {
      item = item as Array<any>;
      item = item[item.length - 1];
    }

    exists = !this.items.filter(
      (i: any) => i?.[this.CheckExistanceByParam] === (item as any)?.[this.CheckExistanceByParam]
    )?.length;
    return exists && itemExists;
  }

  getSearchGetParams(val: any) {
    return { [this.searchGetParam ? this.searchGetParam : 'query']: val };
  }

  setListenAddItems() {
    this.addItems ? this.addItems.subscribe((e: any[]) => this.setSelectItems(e)) : null;
  }

  setInitialValue(items: any[]): any[] {
    items = items.concat(this.initialValue);
    return this.removeDuplicates(items);
  }

  removeDuplicates(items: any[]): any[] {
    const hashMap = new Map();

    items.map((i) => {
      const oldValue = hashMap.get([i[this.RemoveDuplicatesKey]])
        ? hashMap.get([i[this.RemoveDuplicatesKey]])
        : {};
      hashMap.set(i[this.RemoveDuplicatesKey], Object.assign(oldValue, i));
    });

    const newItems = [];
    hashMap.forEach((i) => newItems.push(i));
    return newItems;
  }

  ngOnDestroy() {
    clearTimeout(this.searchTimeout);
    this.subs ? this.subs?.unsubscribe() : null;
  }
}

@Component({
  template: `
    <ng-template ng-option-tmp let-item="item" let-index="index" #ngSelectTranslateOption>
      <span class="ng-arrow-option" [innerHTML]="translate(item)"></span>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgSelectTranslateOptionComponent {
  bindLabel: string = 'name';
  translateLabelPrefix: string;
  @ViewChild('ngSelectTranslateOption', { static: true }) public ngSelectTranslateOption: TemplateRef<any>;

  constructor(private t: TranslateService) {}

  translate(value: any) {
    const translation = this.t.instant(`${this.translateLabelPrefix}${value[this.bindLabel]}`);
    if (translation !== `${this.translateLabelPrefix}${value[this.bindLabel]}`) {
      return translation;
    }
    return value[this.bindLabel];
  }
}

@Component({
  template: `
    <ng-template ng-label-tmp let-item="item" let-index="index" let-clear="clear" #ngSelectTranslateValue>
      <span *ngIf="clearable" aria-hidden="true" class="ng-value-icon left" (click)="clear(item)">×</span>
      <span class="ng-value-label" [innerHTML]="translate(item)"></span>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgSelectTranslateValueComponent implements AfterViewInit, OnDestroy {
  bindLabel: string = 'name';
  translateLabelPrefix: string;
  clearable: boolean = true;
  @ViewChild('ngSelectTranslateValue', { static: true }) public ngSelectTranslateValue: TemplateRef<any>;

  constructor(private t: TranslateService) {}

  ngAfterViewInit() {}

  translate(value: any) {
    const translation = this.t.instant(`${this.translateLabelPrefix}${value[this.bindLabel]}`);
    if (translation !== `${this.translateLabelPrefix}${value[this.bindLabel]}`) {
      return translation;
    }
    return value[this.bindLabel];
  }

  ngOnDestroy() {}
}
