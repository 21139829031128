import { Config } from '@shared/configs/config';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { GbxsoftInputConfig } from '@form/src/lib/gbxsoft-input/interfaces/gbxsoft-input.interface';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { TranslateService } from '@ngx-translate/core';
import { InputV2Component } from '@shared/components/input-v2/input-v2.component';

@Component({
  selector: 'task-selection-change-v2',
  templateUrl: './task-selection-change-v2.component.html',
  styleUrls: ['./task-selection-change-v2.component.scss'],
})
export class TaskSelectionV2ChangeComponent implements OnInit {
  @Input() message: string;
  @Input() title: string;
  @Input() selectConfig = null;
  @Input() config: GbxsoftInputConfig = null;
  @Input() items: Array<any> = [];
  @Input() disabledInput: boolean = false;
  @Output() changeEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild('input') input: InputV2Component;

  form: FormGroup;

  constructor(private fb: FormBuilder, private t: TranslateService) {}

  ngOnInit(): void {
    this.createForm();
  }

  validateNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return isNaN(Number(control?.value?.toString() || '')) ? { isNaN: true } : null;
    };
  }

  createForm() {
    this.form = this.fb.group({
      select: [],
      input: [null, [Validators.max(Config.MAX_NUMBER), this.validateNumber()]],
    });
  }

  changeValue($event) {
    this.changeEvent.emit($event);
  }

  errorMessages(name: string) {
    const messages = Config.validationMessages;
    const control = this.form.get(name);

    if (control?.errors?.max?.max) {
      messages[GbxsoftErrorTypes.max] = this.t.instant('FormErrors.max', {
        number: control.errors?.max?.max,
      });
    }

    if (control?.errors?.isNaN) {
      messages.isNaN = this.t.instant('FormErrors.wrongNumber');
    }

    return Object.keys(messages)
      .filter((key) => Object.keys(control.errors || {}).includes(key))
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: messages[key] });
      }, {});
  }
}
