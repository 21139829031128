import { map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectCreatorApiService } from '../services/project-creator-api.service';
import { BaseProjectCreator } from './base-project-creator.controller';
import { EProjectCreatorFourthStepForm } from '../enums/project-creator-fourth-step.enum';
import { ContactType } from '@modules/contacts/shared/enums/contact-type.enum';
import { Regex } from '@shared/configs/regex';
import { ETimeType } from '../enums/time-type.enum';
import { EConactAddressType } from '../enums/contact-address-type.enum';
import { Project } from '@modules/projects/shared/models/project.model';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { EventEmitter } from '@angular/core';
import { AppInjector } from '@shared/services/app-injector.service';
import { StorageService } from '@core/services/storage.service';
import { Languages } from '@core/services/language.service';
import { ActivatedRoute } from '@angular/router';
import { ProjectStatus } from '@shared/enums/project-status.enum';

export class ProjectCreatorFourthStepController extends BaseProjectCreator {
  additionalForm: FormGroup;
  cdListener: EventEmitter<boolean> = new EventEmitter();

  get defaultFormValues() {
    const contact = this.project?.MainContact;
    return this.getValues(contact);
  }

  getValues(contact: any, savePrevState: boolean = false) {
    return {
      // 1.typ
      [EProjectCreatorFourthStepForm.type]: contact?.type || ContactType.PERSONAL,

      //2.dane osoby

      //3. adres korespondencji
      [EProjectCreatorFourthStepForm.addressType]: contact?.addressType || EConactAddressType.AS_PROJECT,
      [EProjectCreatorFourthStepForm.correspondenceAddress]: contact?.correspondenceAddress || null,
      [EProjectCreatorFourthStepForm.correspondenceTown]: contact?.correspondenceTown || null,
      [EProjectCreatorFourthStepForm.correspondencePostalCode]: contact?.correspondencePostalCode || null,

      //4. dane firmy
      [EProjectCreatorFourthStepForm.companyName]: contact?.companyName || contact?.email || null,
      [EProjectCreatorFourthStepForm.taxNumber]: contact?.taxNumber || null,
      [EProjectCreatorFourthStepForm.taxNumberEU]: contact?.taxNumberEU || null,

      //5. godziny kontaktu
      [EProjectCreatorFourthStepForm.optimalContactTimeType]:
        contact?.optimalContactTimeType || ETimeType.OPTIMAL_CONTACT_TIME_MORNING,
      [EProjectCreatorFourthStepForm.optimalContactTimeAt]: contact?.optimalContactTimeAt || null,

      //6. zgody rodo
      [EProjectCreatorFourthStepForm.rodo]: !!contact || false,
      [EProjectCreatorFourthStepForm.privacy]: !!contact || false,

      [EProjectCreatorFourthStepForm.function]: ['client'],

      [EProjectCreatorFourthStepForm.additionalLastName]: !!savePrevState
        ? this.form.get(EProjectCreatorFourthStepForm.additionalLastName).value
        : contact?.type === ContactType.PERSONAL
          ? contact?.id
          : contact?.childContactPerson?.id || null,
      [EProjectCreatorFourthStepForm.additionalCompanyName]:
        contact?.type === ContactType.COMPANY ? contact?.id : null
    };
  }

  getPersonValues(contact) {
    const injector = AppInjector.getInjector();
    const storage = injector.get(StorageService);

    const countryCode = storage?.Company?.country?.toLowerCase() || Languages?.DE?.toLowerCase();
    return {
      [EProjectCreatorFourthStepForm.firstName]: contact?.firstName || null,
      [EProjectCreatorFourthStepForm.lastName]: contact?.lastName || null,
      [EProjectCreatorFourthStepForm.phoneCountry]: contact?.phoneCountry || countryCode,
      [EProjectCreatorFourthStepForm.phone]: contact?.phone || null,
      [EProjectCreatorFourthStepForm.additionalPhoneCountry]:
        contact?.additionalPhoneCountry || contact?.phoneCountry || countryCode,
      [EProjectCreatorFourthStepForm.additionalPhone]: contact?.additionalPhone || null,
      [EProjectCreatorFourthStepForm.email]: contact?.email || null
    };
  }

  constructor(
    private fb: FormBuilder,
    private api: ProjectCreatorApiService,
    private internal: boolean
  ) {
    super();
    this.createForm(fb);
    this.createPersonalForm();
    this.setValidators();
    this.setPersonTypeValidators();
    this.setCompanyValidators(
      !!(this.form.get(EProjectCreatorFourthStepForm.type)?.value === ContactType.PERSONAL)
    );
  }

  /**
   *Ovveriding base method
   *
   * @memberof ProjectCreatorFourthStepController
   */
  setFormValues() {
    super.setFormValues(true);
    let contact = this.project?.MainContact;
    if (contact?.type === ContactType.COMPANY && !!contact?.childContactPerson) {
      contact = contact?.childContactPerson;
    }
    this.additionalForm.setValue(this.getPersonValues(contact), { emitEvent: false, onlySelf: true });
    this.setValidators();
    this.setPersonTypeValidators();
    this.setCompanyValidators(
      !!(this.form.get(EProjectCreatorFourthStepForm.type)?.value === ContactType.PERSONAL)
    );
  }

  createPersonalForm() {
    const contact = this.project?.MainContact;
    this.additionalForm = this.fb.group(this.getPersonValues(contact));
  }

  setCompanyValidators(reset: boolean = false) {
    this.form
      .get(EProjectCreatorFourthStepForm.companyName)
      .setValidators(reset ? [] : [Validators.required, Validators.maxLength(100)]);
    this.form
      .get(EProjectCreatorFourthStepForm.companyName)
      .updateValueAndValidity({ emitEvent: false, onlySelf: true });
  }

  setPersonTypeValidators() {
    this.additionalForm
      .get(EProjectCreatorFourthStepForm.firstName)
      .setValidators([Validators.required, Validators.maxLength(30)]);
    this.additionalForm
      .get(EProjectCreatorFourthStepForm.firstName)
      .updateValueAndValidity({ emitEvent: false, onlySelf: true });

    this.additionalForm
      .get(EProjectCreatorFourthStepForm.lastName)
      .setValidators([Validators.required, Validators.maxLength(30)]);
    this.additionalForm
      .get(EProjectCreatorFourthStepForm.lastName)
      .updateValueAndValidity({ emitEvent: false, onlySelf: true });

    this.additionalForm
      .get(EProjectCreatorFourthStepForm.email)
      .setValidators([Validators.required, Validators.pattern(Regex.email), Validators.maxLength(100)]);
    this.additionalForm
      .get(EProjectCreatorFourthStepForm.email)
      .updateValueAndValidity({ emitEvent: false, onlySelf: true });
  }

  setContactTime(time: string) {
    this.form.get(EProjectCreatorFourthStepForm.optimalContactTimeType).setValue(time);
  }

  setAddressType(addressType: string) {
    this.form.get(EProjectCreatorFourthStepForm.addressType).setValue(addressType);
  }

  setValidators() {
    this.form.get(EProjectCreatorFourthStepForm.companyName).setValidators([Validators.maxLength(100)]);
    this.form
      .get(EProjectCreatorFourthStepForm.companyName)
      .updateValueAndValidity({ emitEvent: false, onlySelf: true });

    this.form.get(EProjectCreatorFourthStepForm.taxNumber).setValidators([Validators.maxLength(15)]);
    this.form
      .get(EProjectCreatorFourthStepForm.taxNumber)
      .updateValueAndValidity({ emitEvent: false, onlySelf: true });

    this.form.get(EProjectCreatorFourthStepForm.taxNumberEU).setValidators([Validators.maxLength(15)]);
    this.form
      .get(EProjectCreatorFourthStepForm.taxNumberEU)
      .updateValueAndValidity({ emitEvent: false, onlySelf: true });

    this.form
      .get(EProjectCreatorFourthStepForm.correspondenceAddress)
      .setValidators([Validators.maxLength(200)]);
    this.form
      .get(EProjectCreatorFourthStepForm.correspondenceAddress)
      .updateValueAndValidity({ emitEvent: false, onlySelf: true });

    this.form
      .get(EProjectCreatorFourthStepForm.correspondenceTown)
      .setValidators([Validators.maxLength(200)]);
    this.form
      .get(EProjectCreatorFourthStepForm.correspondenceTown)
      .updateValueAndValidity({ emitEvent: false, onlySelf: true });

    this.form
      .get(EProjectCreatorFourthStepForm.correspondencePostalCode)
      .setValidators([Validators.maxLength(200)]);
    this.form
      .get(EProjectCreatorFourthStepForm.correspondencePostalCode)
      .updateValueAndValidity({ emitEvent: false, onlySelf: true });

    this.form
      .get(EProjectCreatorFourthStepForm.optimalContactTimeAt)
      .setValidators([Validators.maxLength(200)]);
    this.form
      .get(EProjectCreatorFourthStepForm.optimalContactTimeAt)
      .updateValueAndValidity({ emitEvent: false, onlySelf: true });

    if (!this.internal) {
      this.form.get(EProjectCreatorFourthStepForm.rodo).setValidators([Validators.requiredTrue]);
      this.form
        .get(EProjectCreatorFourthStepForm.rodo)
        .updateValueAndValidity({ emitEvent: false, onlySelf: true });

      this.form.get(EProjectCreatorFourthStepForm.privacy).setValidators([Validators.requiredTrue]);
      this.form
        .get(EProjectCreatorFourthStepForm.privacy)
        .updateValueAndValidity({ emitEvent: false, onlySelf: true });
    }
  }

  submit(token: string, step?: number): any {
    if (this.form.invalid || this.additionalForm.invalid) return;

    let obj = Object.assign({}, this.form.value);
    const type = obj[EProjectCreatorFourthStepForm.type];

    if (obj[EProjectCreatorFourthStepForm.type] === ContactType.PERSONAL) {
      const objContact = Object.assign({}, this.additionalForm.value);
      obj = Object.assign(obj, objContact);
    }

    if (obj[EProjectCreatorFourthStepForm.addressType] === EConactAddressType.AS_PROJECT) {
      obj[EProjectCreatorFourthStepForm.correspondenceAddress] = this.project?.address;
      obj[EProjectCreatorFourthStepForm.correspondenceTown] = this.project?.town;
      obj[EProjectCreatorFourthStepForm.correspondencePostalCode] = this.project?.postalCode;
    } else {
      obj[EProjectCreatorFourthStepForm.address] = obj[EProjectCreatorFourthStepForm.correspondenceAddress];
      obj[EProjectCreatorFourthStepForm.town] = obj[EProjectCreatorFourthStepForm.correspondenceTown];
      obj[EProjectCreatorFourthStepForm.postalCode] =
        obj[EProjectCreatorFourthStepForm.correspondencePostalCode];
    }

    const injector = AppInjector.getInjector();
    const aRoute = injector.get(ActivatedRoute);
    const isClientForm = aRoute?.snapshot?.firstChild?.firstChild?.data?.hideHeader;

    const finalize = (observer: Observable<any>): Observable<any> => {
      return observer
        .pipe(switchMap(() => (step === 4 ? this.api.publishProject(token, {}) : of({}))))
        .pipe(switchMap(() => this.api.getFullProjectData(token)));
    };

    if (isClientForm && this.project.status === ProjectStatus.ACTIVE) {
      return this.api.getFullProjectData(token);
    }

    if (type === ContactType.PERSONAL) {
      return finalize(this.api.updateFourthStep(token, obj));
    } else {
      const assignCompany = (): Observable<Project> => {
        return this.api
          .updateFourthStep(token, obj)
          .pipe(switchMap(() => this.api.getFullProjectData(token)));
      };

      const assignSubcontact = (project: Project): Observable<any> => {
        project = new Project(project);
        const obj = Object.assign({ type: ContactType.PERSONAL }, this.additionalForm.value);
        let id = project?.MainContact?.id;
        return this.api.createAssignToParent(id, token, obj);
      };
      const method = assignCompany().pipe(switchMap((project: Project) => assignSubcontact(project)));
      return finalize(method);
    }
  }

  submitInternal(id: number, step?: number, isNext?: boolean): any {
    if (this.form.invalid || this.additionalForm.invalid) return;

    let obj = Object.assign({}, this.form.value);
    const type = obj[EProjectCreatorFourthStepForm.type];

    if (obj[EProjectCreatorFourthStepForm.addressType] === EConactAddressType.AS_PROJECT) {
      obj[EProjectCreatorFourthStepForm.correspondenceAddress] = this.project?.address;
      obj[EProjectCreatorFourthStepForm.correspondenceTown] = this.project?.town;
      obj[EProjectCreatorFourthStepForm.correspondencePostalCode] = this.project?.postalCode;

      obj[EProjectCreatorFourthStepForm.address] = this.project?.address;
      obj[EProjectCreatorFourthStepForm.town] = this.project?.town;
      obj[EProjectCreatorFourthStepForm.postalCode] = this.project?.postalCode;
    } else {
      obj[EProjectCreatorFourthStepForm.address] = obj[EProjectCreatorFourthStepForm.correspondenceAddress];
      obj[EProjectCreatorFourthStepForm.town] = obj[EProjectCreatorFourthStepForm.correspondenceTown];
      obj[EProjectCreatorFourthStepForm.postalCode] =
        obj[EProjectCreatorFourthStepForm.correspondencePostalCode];
    }

    const finalize = (observer: Observable<any>, id: number) => {
      return observer
        .pipe(switchMap(() => this.api.getProjectById(id)))
        .pipe(
          map((project) => {
            this.setProject(project);
            this.setFormValues();
            this.cdListener.emit(true);
            return this.project;
          })
        )
        .pipe(
          switchMap((res: Project) => {
            return isNext && step === 4 && res?.status !== 'active'
              ? this.api.activateProject(id, {})
              : of(res);
          })
        );
    };

    if (type === ContactType.PERSONAL) {
      const objContact = Object.assign({}, this.additionalForm.value);
      obj = Object.assign(obj, objContact);
      const contactId = obj[EProjectCreatorFourthStepForm.additionalLastName];
      const method =
        !!contactId && contactId > -1
          ? this.api.assignExistingContactToProjectWithClientFunction(contactId, {
              projectId: this.project.id
            })
          : this.api.assignNewContactToProject(id, obj);
      return finalize(method, id);
    } else {
      const contactId = obj[EProjectCreatorFourthStepForm.additionalLastName];
      const companyId = obj[EProjectCreatorFourthStepForm.additionalCompanyName];

      const assignCompany = (): Observable<Project> => {
        return (
          !!companyId && companyId > -1
            ? this.api.assignExistingContactToProjectWithClientFunction(companyId, {
                projectId: this.project.id
              })
            : this.api.assignNewContactToProject(id, obj)
        ).pipe(switchMap(() => this.api.getProjectById(id)));
      };

      const assignSubcontact = (project: Project): Observable<any> => {
        const obj = Object.assign({ type: ContactType.PERSONAL }, this.additionalForm.value);
        project = new Project(project);

        return !!contactId && contactId > -1
          ? this.api.assignSubcontactToParent(contactId, {
              parentId: project?.MainContact?.id,
              isContactPerson: true
            })
          : this.api.createExtendedContact(obj).pipe(
              switchMap((contact: Contact) =>
                this.api.assignSubcontactToParent(contact?.id, {
                  parentId: project?.MainContact?.id,
                  isContactPerson: true
                })
              )
            );
      };

      const assignmentFlow = assignCompany().pipe(switchMap((project: Project) => assignSubcontact(project)));
      return finalize(assignmentFlow, id);
    }
  }
}
