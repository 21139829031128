import { BaseHttpService } from '@core/http/base-http.service';
import { EventEmitter, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StickyFooterService extends BaseHttpService {
  emitter: EventEmitter<StickyFooterEvent> = new EventEmitter();

  constructor() {
    super();
  }
}

export class StickyFooterEvent {
  type: StickyFooterEventType;
}

export enum StickyFooterEventType {
  SUBMITTED,
  START_SAVING,
  END_SAVING,
  DISCARD,
}
