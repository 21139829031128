import { StorageService } from '@core/services/storage.service';
import { TaskType } from '@shared/enums/task-type.enum';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TaskSidenavManager } from '@shared/modules/task-sidenav/managers/task-sidenav.manager';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Task } from '@shared/models/task.model';
import { Config } from '@shared/configs/config';
import {
  ListTaskEvent,
  ListTaskEventType,
  ListTaskService,
} from '@shared/modules/list/services/list-task.service';
import { TASK_SIDENAV_FORM } from '@shared/modules/task-sidenav/const/task-sidenav-form';

@Component({
  selector: 'task-basic-preview-data',
  templateUrl: './task-basic-preview-data.component.html',
  styleUrls: ['./task-basic-preview-data.component.scss'],
})
export class TaskBasicPreviewDataComponent implements OnInit, OnDestroy {
  PermissionsGroups = PermissionsGroups;
  Config = Config;
  TaskType = TaskType;
  @ViewChild('tooltipCopy') tooltipCopy;
  copied: boolean = true;
  sub: Subscription = new Subscription();
  taskManager: TaskSidenavManager = new TaskSidenavManager();

  constructor(
    public storage: StorageService,
    public service: TaskSidenavService,
    private changes: ChangeDetectorRef,
    private listTaskService: ListTaskService,
  ) {}

  ngOnInit() {
    this.listenListTaskEvents();
    this.subscribeTaskUpdate();
  }

  listenListTaskEvents() {
    const actions = [ListTaskEventType.UPDATE_TASK];
    const sub = this.listTaskService.emitter
      .pipe(filter((i) => actions.indexOf(i.type) > -1))
      .subscribe(this.updateTask.bind(this));
    this.sub.add(sub);
  }

  updateTask(e: ListTaskEvent) {
    if (e.data.id === this.service.task.id) {
      this.service.initPreviewState(e.data);
      this.service.form.get(TASK_SIDENAV_FORM.status).patchValue(e.data.status, { emitEvent: false });
      this.changes.detectChanges();
    }
  }

  onStatusChange(task: Task) {
    this.service.setStatusCompleted(task);
  }

  checkedSubtasks(checked: boolean) {}

  subscribeTaskUpdate() {
    const sub = this.service.taskActionEmitter.subscribe(() => this.changes.detectChanges());
    this.sub.add(sub);
  }

  openTaskEdit() {
    this.taskManager.editTask(this.service?.task);
  }

  openParentPreview(task: Task) {
    this.taskManager.previewTask(task);
  }

  showTooltip() {
    setTimeout(() => {
      this.tooltipCopy.show();
      setTimeout(() => this.tooltipCopy.hide(), 800);
    }, 0);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
